import React from "react";
import {generateDesignTypes} from "../shared/constants.js";
import {useTranslation} from 'react-i18next'
const CustomDesign = () => {
  const {t, i18n} = useTranslation()
  const [designTypes, setDesignTypes] = React.useState(generateDesignTypes())
  const [designStyle, setDesignStyle] = React.useState(designTypes[0].src);
  const [activeDesign, setActiveDesign] = React.useState(1);
  React.useEffect(() => {
    setDesignTypes(generateDesignTypes())
  }, [i18n.language])
  const handleDesignChange = (el) => {
    setDesignStyle(el.src);
    setActiveDesign(el.id);
  };
  return (
    <section className="custom--container">
      <div className="custom--container_descr">
        <h2>{t('customDesign.title')}</h2>
        <p>{t('customDesign.subtitle')}</p>
        <p>
        {t('customDesign.text')}
        </p>
        <div className="custom--container_items">
          {designTypes.map((el) => (
            <div key={el.id} className="custom-wrapper">
              <div
                className={
                  activeDesign === el.id
                    ? "custom--item active"
                    : "custom--item"
                }
                onClick={() => handleDesignChange(el)}
              ></div>
              <p>{el.name}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="custom-design_image">
        <img src={designStyle} alt="custom design" />
      </div>
    </section>
  );
};

export default CustomDesign;
