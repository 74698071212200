import React from "react";
import Form from "../components/Form";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const Contacts = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "auto";
  }, []);
  const contactsInfo = [
    { id: 1, name: "info@tramptec.com", href: "mailto:info@citio.ru" },
    // { id: 2, name: "+4791654792", href: "" },
  ];
  return (
    <>
      <Helmet>
        <title>Tramptec - Contacts</title>
      </Helmet>
      <section className="contacts--container">
        <div className="contacts--wrapper">
          <div className="contacts--text">
            <div className="contacts--text_about">
              <h2>{t("contacts.title")}</h2>
            </div>
            <div className="about-container">
              {contactsInfo.map((el) => (
                <div className="about__item" key={el.id}>
                  <a href={el.href}>{el.name}</a>
                </div>
              ))}
            </div>
          </div>
          <div className="contacts--links">
            <h3>{t("contacts.subtitle")}</h3>
            <div className="contacts--links__wrapper">
              <div className="link_item">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.pinterest.ru/igratexru"
                >
                  Pinterest
                </a>
              </div>
              <div className="link_item">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/tramptec"
                >
                  Instagram
                </a>
              </div>
              <div className="link_item">
                <a target="_blank" rel="noreferrer" href="https://t.me/igratex">
                  Telegram
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="contacts--form">
          <p>{t("contacts.text")}</p>
          <Form />
        </div>
      </section>
    </>
  );
};

export default Contacts;
