import React from "react";
import { Outlet } from "react-router"
import Header from "./Header";
import Footer from "./Footer";
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";

const Layout = () => {
  return (
    <>
      <I18nextProvider i18n={i18n}>
        <Header />
        <main>
          <Outlet />
        </main>
        <Footer />
      </I18nextProvider>
    </>
  );
};

export default Layout;
