import React from "react";
import logoTramptec from "../assets/icons/logo_m.svg";
import { useTranslation } from "react-i18next";
const Environment = () => {
  const { t } = useTranslation();
  return (
    <section className="environment-container">
      <div className="environment-container_logo">
        <img src={logoTramptec} alt="tramptec logo" />
      </div>
      <div className="environment-container_text">
        <h2>{t("environment.title")}</h2>
        <p>{t("environment.subtitle")}</p>
      </div>
    </section>
  );
};

export default Environment;
