import React from "react";
import { generateConstructorTypes } from "../../../shared/constants.js";
import { photos } from "../../../assets/images/Types/index.js";
import { useTranslation } from "react-i18next";
import Button from "../../Button.jsx";
import TrampolinoParams from "./TrampolinoParams";


const TrampolineConstructor = () => {
  const { t, i18n } = useTranslation();
  const [selectedParams, setSelectedParams] = React.useState({
    form: "squircle",
    protection: "ring",
    integratedlighting: "without",
    membrane: "rubber_sheet",
  });
const [constructorTypes, setConstructorTypes] = React.useState(
  generateConstructorTypes()
)
  const [itemForm, setItemForm] = React.useState(constructorTypes[0].types[0]);
  React.useEffect(() => {
    setConstructorTypes(generateConstructorTypes());
  }, [i18n.language]);
  React.useEffect(() => {
    const selectedPath = Object.values(selectedParams).join("");
    setSelectedPath(selectedPath);
  }, [selectedParams]);
  const [selectedPath, setSelectedPath] = React.useState(
    Object.values(selectedParams).join("")
  );
  const [selectedProtection, setSelectedProtection] = React.useState('ring')
  const [selectedLighting, setSelectedLighting] = React.useState('without')
  const handleFormChange = (item) => {
    setItemForm(item);
    if (item.id === 11) {
      setSelectedParams({
        form: "squircle",
        protection: selectedProtection,
        integratedlighting: selectedLighting,
        membrane: "rubber_sheet",
      });
    } else if (item.id === 12) {
      setSelectedParams({
        form: "round",
        protection: selectedProtection,
        integratedlighting: selectedLighting,
        membrane: "rubber_sheet",
      });
    } else if (item.id === 13) {
      setSelectedParams({
        form: "square",
        protection: "tiles",
        integratedlighting: "without",
        membrane: "belt_fabric",
      });
    }
  };
  const handleParams = (el, item) => {
    const component = el.toLowerCase().replace(/\s/g, "");
    if (el === "Protection") {
      setSelectedParams(() => {
        return {
          ...selectedParams,
          [component]: item,
        }
      });
      setSelectedProtection(item)
    }
    if (el === "Integrated lighting") {
      setSelectedParams(() => {
        return {
          ...selectedParams,
          [component]: item,
        };
      });
      setSelectedLighting(item)
    }
  };
  return (
    <section className="constructor">
      <div className="constructor--dicsr">
        <div className="title">
          <h3>{t("constructor.title")}</h3>
          <p>{t("constructor.subtitle")}</p>
        </div>
        <div className="types">
          {constructorTypes.map((el) => (
            <div className="types--wrapper" key={el.id}>
              <p>{el.name}</p>
              <div className="types--items">
                {el.types.map((item) => (
                  <div className="item" key={item.id}>
                    <div onClick={() => handleFormChange(item)}>
                      {itemForm.id === item.id ? item.activeImage : item.image}
                    </div>
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <TrampolinoParams props={itemForm} handleParams={handleParams}/>
        </div>
      </div>
      <div className="constructor--image">
        <img src={photos[selectedPath]} alt="constructor item trampolino" />
      </div>
      <Button />
    </section>
  );
};

export default TrampolineConstructor;
