import T3Q_SKP from './T 3Q.skp'
import T3S_SKP from './T 3S.skp'
import T3R_SKP from './T 3R.skp'
import T100QF_SKP from './T 100Q F.skp'
import T100RM_SKP from './T 100R M.skp'
import T100RR_SKP from './T 100R R.skp'
import T100SM_SKP from './T 100S M.skp'
import T100SR_SKP from './T 100S R.skp'
import T150QF_SKP from './T 150Q F.skp'
import T150RM_SKP from './T 150R M.skp'
import T150RR_SKP from './T 150R R.skp'
import T150SM_SKP from './T 150S M.skp'
import T150SR_SKP from './T 150S R.skp'
import T300QF_SKP from './T 300Q F.skp'
export const skp = { T3Q_SKP, T3S_SKP, T3R_SKP, T100QF_SKP, T100RM_SKP, T100RR_SKP, T100SM_SKP, T100SR_SKP, T150QF_SKP, T150RM_SKP, T150RR_SKP, T150SM_SKP, T150SR_SKP, T300QF_SKP }