import squaretileswithoutbelt_fabric from './square_tiles_without_belt_fabric.webp'
import squirclemoundwithrubber_sheet from './squircle_mound_with_rubber_sheet.webp'
import squirclemoundwithoutrubber_sheet from './squircle_mound_without_rubber_sheet.webp'
import squircleringwithrubber_sheet from './squircle_ring_with_rubber_sheet.webp'
import squircleringwithoutrubber_sheet from './squircle_ring_without_rubber_sheet.webp'
import roundmoundwithrubber_sheet from './round_mound_with_rubber_sheet.webp'
import roundmoundwithoutrubber_sheet from './round_mound_without_rubber_sheet.webp'
import roundringwithrubber_sheet from './round_ring_with_rubber_sheet.webp'
import roundringwithoutrubber_sheet from './round_ring_without_rubber_sheet.webp'
import bigR100R from './bigr100.jpg'
import bigR150 from './bigr150.jpg'
import bigCircle from './bigCircle.webp'
import squareBig from './squareBig.jpg'
import rectangle from './rectangleType.webp'
import bigt100R from './bigt100r.webp'
import bigCircleLed from './bigCircleLed.webp'
import bigR150Led from './bigr150Led.webp'
import bigS100Led from './bigs150Led.webp'
import bigt100RLed from './bigt100RLed.webp'
export const photos = {
    squirclemoundwithrubber_sheet,
    squirclemoundwithoutrubber_sheet,
    squircleringwithrubber_sheet,
    squircleringwithoutrubber_sheet,
    squaretileswithoutbelt_fabric,
    roundmoundwithrubber_sheet,
    roundmoundwithoutrubber_sheet,
    roundringwithrubber_sheet,
    roundringwithoutrubber_sheet,
    bigR100R,
    bigR150,
    bigCircle,
    squareBig,
    rectangle,
    bigt100R,
    bigCircleLed,
    bigR150Led,
    bigS100Led,
    bigt100RLed,
}
