import React from "react";
import {useTranslation} from 'react-i18next'
const Reliability = () => {
  const {t} = useTranslation()
  return (
    <section className="reliability--container">
      <p>
        <span>{t('reliability.title')} </span>
        {t('reliability.subtitle')}
      </p>
      <div className="gaurantee--container">
        <div className="gaurantee--container_item">
          <h3>{t('reliability.temp.prop1')}<br />{t('reliability.temp.prop2')}</h3>
          <p>{t('reliability.temp.descr')}</p>
        </div>
        <div className="gaurantee--container_item">
          <h3>{t('reliability.age.prop1')}</h3>
          <p>{t('reliability.age.text')}</p>
        </div>
        <div className="gaurantee--container_item">
          <h3>{t('reliability.load.prop1')}</h3>
          <p>{t('reliability.load.text')}</p>
        </div>
        <div className="gaurantee--container_items">
        <div className="gaurantee--container_item">
            <h3>{t('reliability.guarantee.prop1')}</h3>
            <p>{t('reliability.guarantee.text1')}</p>
          </div>
          <div className="gaurantee--container_item">
          <h3>{t('reliability.guarantee.prop2')}</h3>
            <p>{t('reliability.guarantee.text2')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reliability;
