import React from "react";
import { Link } from "react-router-dom";
import {generateGroundTypes} from '../../shared/constants'
import {useTranslation}from 'react-i18next'
const GroundType = () => {
  const {t, i18n} = useTranslation()
  const [inGroundTypes, setInGroundTypes] = React.useState(generateGroundTypes())
  React.useEffect(() => {
    setInGroundTypes(generateGroundTypes())
  }, [i18n.language])
  return (
    <div className="ground-container">
      <h2>{t('catalog.in_ground.title')}</h2>
      <div className="ground-container_wrapper">
        {inGroundTypes.map((el) => (
          <Link to={`/catalog/round/${el.id}`} key={el.id}>
            <div className="ground_item">
              <div>
                <img src={el.src} alt="trampolino in ground" />
              </div>
              <h3>{el.title}</h3>
              <p>{el.subTitle}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default GroundType;
