import { Routes, Route } from 'react-router-dom'
import React from 'react';
import Layout from './components/Layout';
import Home from './pages/Home'
import Catalog from './pages/Catalog'
import Contacts from './pages/Contacts'
import './scss/app.scss'
import CatalogRouter from './components/CatalogItems/CatalogRouter';
import Not4ound from './pages/Not4ound';
import Politics from './pages/Politics';
import BusinessCard from './pages/BusinessCard';
import { Helmet } from 'react-helmet'
function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta http-equiv="Content-Language" content="en, ru" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="canonical" href="https://tramptec.com" />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          lang="en"
          content="Outdoor in-ground trampolines for playgrounds."
        />
        <meta
          name="keywords"
          lang="en"
          content="tramptec, tramp, trampoline, trampolines, premium, master, round, mound, squircle, sircle, minitramp, kidstramp, education, programme, albatross, experience, innovation, technology, power, controllability,"
        />
        <meta name="author" lang="en" content="Tramptec" />
        <meta
          name="description"
          lang="ru"
          content="Изготовление и установка оборудования для детских и спортивных игровых площадок в Москве. Производство антивандальных батутов европейского качества."
        />
        <meta
          name="keywords"
          lang="ru"
          content="встроенный батут, встраиваемый батут, производство, грунтовой батут, всесезонный батут, антивандальный батут, уличный батут, в наличии, на складе, от производителя, батут для детской площадки"
        />

        <meta
          property="og:title"
          content="Outdoor in-ground trampolines for playgrounds."
        />
        <meta
          property="og:description"
          content="Production of high-quality anti-vandal trampolines for commercial and public playgrounds, schools, parks."
        />
        <meta
          name="twitter:title"
          content="Outdoor in-ground trampolines for playgrounds."
        />
        <meta
          name="twitter:description"
          content="Production of high-quality anti-vandal trampolines for commercial and public playgrounds, schools, parks."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:url" content="https://tramptec.com" />
        <meta
          property="og:image"
          content="https://tramptec.com/static/media/Tramptec_link.24bff729f4abaec79e3e.png"
        />
        <meta name="author" lang="ru" content="Трамптек" />
        <meta property="og:site_name" content="Tramptec" />
        <meta
          property="twitter:image"
          content="https://tramptec.com/static/media/Tramptec_link.24bff729f4abaec79e3e.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <title>Tramptec</title>
      </Helmet>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='catalog' element={<Catalog />} />
          <Route path='catalog/:title/:model' element={<CatalogRouter />} />
          <Route path='contacts' element={<Contacts />} />
          <Route path="politics" element={<Politics />} />
        </Route>
        <Route path="/v/:name" element={<BusinessCard />} />
        <Route path='*' element={<Not4ound />} />
      </Routes>
    </div>
  );
}

export default App;
