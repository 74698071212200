import T3Q_2D from './Set 3Q.dwg'
import T3S_2D from './Set 3S.dwg'
import T3R_2D from './Set 3R.dwg'
import T100QF_2D from './T 100Q F.dwg'
import T100RM_2D from './T 100R M.dwg'
import T100RR_2D from './T 100R R.dwg'
import T100SM_2D from './T 100S M.dwg'
import T100SR_2D from './T 100S R.dwg'
import T150QF_2D from './T 150Q F.dwg'
import T150RM_2D from './T 150R M.dwg'
import T150RR_2D from './T 150R R.dwg'
import T150SM_2D from './T 150S M.dwg'
import T150SR_2D from './T 150S R.dwg'
import T300QF_2D from './T 300Q F.dwg'
export const dwg_2d = { T3Q_2D, T3S_2D, T3R_2D, T100QF_2D, T100RM_2D, T100RR_2D, T100SM_2D, T100SR_2D, T150QF_2D, T150RM_2D, T150RR_2D, T150SM_2D, T150SR_2D, T300QF_2D }