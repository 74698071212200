import React from "react";
import ledPhoto from "../assets/images/ledTrampo.webp";
import {useTranslation} from 'react-i18next'
const LedTrampo = () => {
  const {t} = useTranslation()
  // const [isActivePopup, setIsActivePopup] = React.useState(false);
  return (
    <section className="led--container">
      <div className="led--wrapper">
        <div className="led--description">
          {/* <div className="led--description_text" onClick={() => setIsActivePopup(!isActivePopup)}>
            <p>{t('ledContainer.title')}</p>
          </div>
          {isActivePopup && ( */}
            <div className="led--description_popup">
              <ul>
                <li>{t('ledContainer.props.prop1')}</li>
                <li>{t('ledContainer.props.prop2')}</li>
                <li>
                  <p><span>{t('ledContainer.props.prop3')}</span> {t('ledContainer.props.prop4')}</p>
                </li>
                <li>{t('ledContainer.props.prop5')}</li>
              </ul>
            </div>
          {/* )} */}
        </div>
       <div className="led--image">
       <img src={ledPhoto} alt="led trampolino" />
       </div>
      </div>
      <h2>
      {t('ledContainer.text')}<br />
      {t('ledContainer.subtext')}
        </h2>
    </section>
  );
};

export default LedTrampo;
