import React from 'react'
import SquircleItem from '../Cards/Squircle/Squircle'
import RoundItem from '../Cards/Round/RoundItem'
import SetItem from '../Cards/Sets/SetItem'
import { useParams } from 'react-router-dom'

const CatalogRouter = () => {
    const {title} = useParams()
    if (title === 'mound') {
        return <SquircleItem />
    } else if (title === 'round') {
        return <RoundItem /> 
    } else if (title === 'set') {
        return <SetItem/> 
    } else {
        return <div style={{ fontSize: '50px' , width: '100%', heigth: '90px', textAlign:'center', padding: '150px 0', color: 'orange'}}>doesnt exist</div>;
    }
}

export default CatalogRouter