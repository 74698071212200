import React from "react";
import {Link} from 'react-router-dom'
import {generateMoundTypes} from '../../shared/constants'
import {useTranslation} from 'react-i18next'
const MoundType = () => {
const {t, i18n} = useTranslation()
const [inMoundTypes, setInmoundTypes] = React.useState(generateMoundTypes())
React.useEffect(() => {
  setInmoundTypes(generateMoundTypes())
}, [i18n.language])
  return (
    <div className="mound-container">
      <h2>{t('catalog.in_mound.title')}</h2>
      <div className="mound-container_wrapper">
        {inMoundTypes.map((el) => (
          <Link to={`/catalog/mound/${el.id}`} key={el.id}>
            <div className="mound_item">
                <div><img src={el.src} alt="trampolino in mound" /></div>
                <h3>{el.title}</h3>
                <p>{el.subTitle}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MoundType;
