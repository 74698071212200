import React from "react";
import { generateCharacteristics, generateColumn } from "../shared/constants";
import { useTranslation } from "react-i18next";
const TableContainer = () => {
  const { i18n } = useTranslation();
  const tableRef = React.useRef(null);
  const [characteristics, setCharacteristics] = React.useState(
    generateCharacteristics()
  );
  const [columns, setColumns] = React.useState(generateColumn());
  const [activeItem, setActiveItem] = React.useState(0);
  React.useEffect(() => {
    setCharacteristics(generateCharacteristics());
    setColumns(generateColumn());
  
    const handleTouch = () => {
      const scrollPosition = tableRef.current.scrollLeft;
      setActiveItem(scrollPosition);
    };

    const charTypesElement = document.querySelector('.char-types');
  
    if (charTypesElement) {
      charTypesElement.addEventListener("scroll", handleTouch);

      return () => {
        charTypesElement.removeEventListener("scroll", handleTouch);
      };
    }
    return () => {};
  }, [i18n.language]);
  
  return (
    <section className="environment-container_chasracteristic">
      <div className="char-description">
        {columns.map((el) => (
          <div key={el.id}>
            <h3>{el.name}</h3>
          </div>
        ))}
      </div>
      <div className="char-types" ref={tableRef}>
        {characteristics.map((element) => (
          <div key={element.id} className="char-types_name">
            <div className="char-types_image">
              <img src={element.src} alt="trampolino type" />
            </div>
            <h4>{element.name}</h4>
            <div className="type_item">
              {element.size.map((item) => (
                <div key={item.id} className="char-types_name-item_container">
                  <div
                    className={item.state ? "circle active" : "circle"}
                  ></div>
                  <p>{item.prop}</p>
                </div>
              ))}
            </div>
            <div className="type_item">
              {element.led.map((item) => (
                <div key={item.id} className="char-types_name-item_container">
                  <div
                    className={item.state ? "circle active" : "circle"}
                  ></div>
                  <p>{item.prop}</p>
                </div>
              ))}
            </div>
            <div className="type_item">
              {element.membrane.map((item) => (
                <div key={item.id} className="char-types_name-item_container">
                  <div
                    className={item.state ? "circle active" : "circle"}
                  ></div>
                  <p>{item.prop}</p>
                </div>
              ))}
            </div>
            <div className="type_item">
              {element.custom.map((item) => (
                <div key={item.id} className="char-types_name-item_container">
                  <div
                    className={item.state ? "circle active" : "circle"}
                  ></div>
                  <p>{item.prop}</p>
                </div>
              ))}
            </div>
            <div className="type_item">
              {element.protection.map((item) => (
                <div key={item.id} className="char-types_name-item_container">
                  <div
                    className={item.state ? "circle active" : "circle"}
                  ></div>
                  <p>{item.prop}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="slides-items">
        <div
          className={activeItem < 166 ? "slide-item active" : "slide-item"}
        ></div>
        <div
          className={
            activeItem > 166 && activeItem < 344
              ? "slide-item active"
              : "slide-item"
          }
        ></div>
        <div
          className={activeItem > 344 ? "slide-item active" : "slide-item"}
        ></div>
      </div>
    </section>
  );
};

export default TableContainer;
