import React from "react";
import { useTranslation } from "react-i18next";
import bgLogo from '../assets/images/bg-tramptec.jpeg'
import Button from "./Button";
const Outdoor = () => {
  const {t} = useTranslation()
  return (
    <section className="container">
      <div className="container--image"><img src={bgLogo} alt="jumping boy" /></div>
      <div className="container--discr">
        <p>{t('outdors.text')}</p>
       <Button />
      </div>
    </section>
  );
};

export default Outdoor;
