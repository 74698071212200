import React from "react";
import customeImage from "../assets/images/Types/brightImage.png";
import darkImage from "../assets/images/Types/darkImage.png";
import slicedTrampo from "../assets/images/slidedTrampo.png";
import { useTranslation } from "react-i18next";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
const Quality = () => {
  const { t } = useTranslation();
  const sectionRef = React.useRef(null);
  const qualityImages = [
    { id: 1, src: customeImage, name: "first_image" },
    { id: 2, src: darkImage, name: "second_image" },
    { id: 3, src: slicedTrampo, name: "third_image" },
  ];
  gsap.registerPlugin(ScrollTrigger);
  React.useEffect(() => {
    const topIndex = window.innerWidth > 430 ? '55vh' : '50vh'
    const imageAnimationCoordinats = window.innerWidth > 430 ? '15%' : '15%'
    const tramsformIndex = '0'
    gsap.set(".quality--images_item", {
      clipPath: () => "inset(0px 0px 0px 0px)",
    });
    gsap.set(".third_image", {
      clipPath: () => "inset(0px 0px 0px 0px)",
    });

    const textAnimation = gsap.to(".quality--texts_item-text p", {
      position: 'sticky',
      top: 0,
      opacity: 0,
      ease: "none",
    });
    const yeallowTextAnimation = gsap.to(".quality--texts_item-text2 p", {
      y: 10,
      opacity: 1,
      ease: "sine.out",
    });
    const imagePositionSticky = gsap.to(".quality--images", {
      position: "sticky",
      top: topIndex,
    });
    const firstTwoAnimation = gsap.to(
      ".quality--images_item:not(:last-child)",
      {
        clipPath: () => "inset(0px 0px 100% 0px)",
        stagger: 1,
        ease: "none",
      }
    );
    const tipsText1 = gsap.to(".text1", {
      opacity: 1,
    });

    const tipsText2 = gsap.to(".text2", {
      opacity: 1,
    });
    const tipsText3 = gsap.to(".text3", {
      opacity: 1,
    });
    const tipsText4 = gsap.to(".text4", {
      opacity: 1,
    });
    const staticBlock = gsap.to('.quality--images', {
      className: 'quality--images static'
    })
    // ScrollTrigger для первых двух элементов
    ScrollTrigger.create({
      trigger: ".quality--container",
      start: `${imageAnimationCoordinats} top`,
      end: "75% center",
      animation: firstTwoAnimation,
      scrub: true,
      // markers: true,
    });
    ScrollTrigger.create({
      trigger: ".quality--container",
      start: "top top",
      end: "20% top",
      animation: textAnimation,
      scrub: true,
      // markers: true,
    });
    ScrollTrigger.create({
      trigger: ".quality--container",
      start: "top top",
      end: "top top",
      animation: imagePositionSticky,
      scrub: true,
      // markers: true,
    });
    ScrollTrigger.create({
      trigger: ".quality--texts_item-text2",
      start: "-30% top",
      end: "top top",
      animation: yeallowTextAnimation,
      scrub: true,
      // markers: true,
    });
    ScrollTrigger.create({
      trigger: ".three",
      start: "-2% top",
      end: "12% top",
      animation: tipsText1,
      scrub: true,
      // markers: true,
    });
    ScrollTrigger.create({
      trigger: ".three",
      start: "7% top",
      end: "19% top",
      animation: tipsText4,
      scrub: true,
    });
    ScrollTrigger.create({
      trigger: ".three",
      start: "14% top",
      end: "28% top",
      animation: tipsText3,
      scrub: true,
      // markers: true,
    });
    ScrollTrigger.create({
      trigger: ".three",
      start: "25% top",
      end: "35% top",
      animation: tipsText2,
      scrub: true,
      // markers: true,
    });
    ScrollTrigger.create({
      trigger: ".three",
      start: "100% bottom",
      end: "100% bottom",
      animation: staticBlock,
      scrub: true,
      // markers: true,
    })
  }, []);
  return (
    <section ref={sectionRef} className="quality--container">
      <div className="quality--images">
        {qualityImages.map((el) => (
          <div key={el.id} className={`quality--images_item ${el.name}`}>
            <img src={el.src} alt="trampolinos slider" />
            <div className="three_wrapper">
            <p className="text1">
              {t("quality.activeTitle1")}
              <span> {t("quality.activeSubtitle1")}</span>
            </p>
            <p className="text2">
              {t("quality.activeTitle2")}
              <span> {t("quality.activeSubtitle2")}</span>
            </p>
            <p className="text3">
              {t("quality.activeTitle3")}
              <span> {t("quality.activeSubtitle3")}</span>
            </p>
            <p className="text4">
              {t("quality.activeTitle4")}
              <span> {t("quality.activeSubtitle4")}</span>
            </p>
          </div>
          </div>
        ))}
      </div>
      <div className="quality--texts">
        <div className="quality--texts_item-text">
          <p>
            <span>{t("quality.title")} </span>
            {t("quality.subtitle")}
          </p>
        </div>
        <div className="quality--texts_item-text2">
          <p>
            <span>{t("quality.title")} </span>
            {t("quality.subtitle")}
          </p>
        </div>
        <div className="three quality--texts_item-text3">
        </div>
      </div>
    </section>
  );
};

export default Quality;
