import React from "react";
import { generateSetTypes } from "../../shared/constants";
import { useTranslation } from "react-i18next";
import {Link} from 'react-router-dom'
const Sets = () => {
  const { t, i18n } = useTranslation();
  const [inSetsType, setInSetType] = React.useState(generateSetTypes());
  React.useEffect(() => {
    setInSetType(generateSetTypes());
  }, [i18n.language]);
  return (
    <div className="sets-container">
      <h2>{t("catalog.sets.title")}</h2>
      <div className="sets-container_wrapper">
        {inSetsType.map((el) => (
          <Link to={`/catalog/set/${el.id}`} key={el.id}>
            <div className="sets_item">
              <img src={el.src} alt="sets of trampolinos" />
              <div className="sets-text">
                <h3>{el.title}</h3>
                <p>{el.subTitle}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Sets;
