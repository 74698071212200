import React from "react";
import fabric from "../assets/images/Types/square_tiles_without_belt_fabric.webp";
import rubber from "../assets/images/Types/squircle_ring_without_rubber_sheet.webp";
import { useTranslation } from "react-i18next";
const Membranes = () => {
  const {t} = useTranslation()
  return (
    <section className="membranes--container">
      <h2>{t('membranes.title')}</h2>
      <div className="membranes--wrappper">
        <div className="rubber">
          <h3>{t('membranes.rubber.title')}</h3>
          <p>
          {t('membranes.rubber.subtitle')}
          </p>
          <p>{t('membranes.rubber.text')}</p>
          <div className="rubber--image">
            <img src={rubber} alt="rubber sheet" />
            <div className="svg--custom">
              <p>{t('membranes.rubber.custom')}</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="82"
                height="82"
                viewBox="0 0 82 82"
                fill="none"
              >
                <g clipPath="url(#clip0_342_86)">
                  <path
                    d="M40.9984 7.03441C50.3777 -2.3448 65.5853 -2.3448 74.9646 7.03441C84.3438 16.4136 84.3438 31.6213 74.9646 41.0005C84.3438 50.3797 84.3438 65.5874 74.9646 74.9666C65.5853 84.3458 50.3777 84.3458 40.9984 74.9666C31.6192 84.3458 16.4115 84.3458 7.03441 74.9666C-2.3448 65.5874 -2.3448 50.3797 7.03441 41.0026C-2.3448 31.6234 -2.3448 16.4178 7.03441 7.03857C16.4136 -2.34064 31.6192 -2.34064 40.9984 7.03857"
                    fill="#E1E051"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_342_86">
                    <rect width="82" height="82" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div className="fabric">
          <h3>{t('membranes.belt.title')}</h3>
          <p>
          {t('membranes.belt.subtitle')}
          </p>
          <p>{t('membranes.belt.text')}</p>
          <div className="fabric--image">
            <img src={fabric} alt="belt fabric" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Membranes;
