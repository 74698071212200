import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Dimensions = ({ params }) => {
  const { t } = useTranslation();
  const handleDownload = (item) => {
    const dwgFilePath = item.file;
    const downloadLink = document.createElement('a');
    downloadLink.href = dwgFilePath;
    downloadLink.download = `${item.fileName}`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <div className="dimensions--container">
      <div className="dimensions--container_files">
        <h3>{t("downloads.title")}</h3>
        <div className="files_link">
          {params.links.map((el) => (
            <>
              {el.state && (
                <div key={el.id} className="link_item" onClick={() => handleDownload(el)}>
                  <p>{el.name}</p>
                </div>
              )}
            </>
          ))}
        </div>
        <h3>{t("downloads.delivery")}</h3>
        <div className="files_link">
          <div className="link_item">
            <Link to="/contacts">{t("downloads.contacts")}</Link>
          </div>
        </div>
        <h3>{t("downloads.certifcation")}</h3>
        <p>{t("downloads.certifcationText")}</p>
      </div>
      <div className="dimensions--container_sizes">
        <h3>{t("downloads.dimensions")}</h3>
        <div className="dimensions--text">
          {params.details.map((el) => (
            <p key={el.id}>{el.name}</p>
          ))}
        </div>
        <div className="dimensions--image">
        <img src={params.scheme} alt="trampolino type" />
        </div>
      </div>
    </div>
  );
};

export default Dimensions;
