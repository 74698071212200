import React from "react";
import { useParams, Navigate } from "react-router-dom";
import { cardData } from "../shared/cards";
import logotype from "../assets/images/giflogo.gif";
import arrow from "../assets/icons/downArrow.svg";
import { Helmet } from "react-helmet";
const BusinessCard = () => {
  const { name } = useParams();
  if (!cardData[name]) {
    return <Navigate to="/404" />;
  }
  const handleDownload = () => {
    const image = new Image(); // Создаем новый элемент изображения
    image.src = data.userPhoto; // Устанавливаем источник изображения

    image.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0);
      const encodedImage = canvas.toDataURL("image/png").split(",")[1];

      const vcardContent = `BEGIN:VCARD
VERSION:4.0
N:${data.userName.lastName};${data.userName.firstName};;;
FN:${data.userName.firstName} ${data.userName.lastName}
ORG:Tramptec
TITLE:${data.position}
TEL:${data.phone.text}
EMAIL:${data.email.text}
PHOTO;ENCODING=b;TYPE=PNG:${encodedImage}
URL;Web-site:https://tramptec.com
URL;WhastApp:${data.whatsappLink}
URL;Telegram:${data.telegramLink}
END:VCARD`;

      const blob = new Blob([vcardContent], { type: "text/vcard" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${data.file_name}.vcf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
  };
  const data = cardData[name];
  const titleCard = `${data.userName.firstName} ${data.userName.lastName}`;
  const descriptionCard = `${data.position}`;
  return (
    <>
      <Helmet>
        <meta name="description" lang="en" content={titleCard} />
        <meta
          name="keywords"
          lang="en"
          content={descriptionCard}
        />
        <meta property="og:title" content={titleCard} />
        <meta property="og:description" content={descriptionCard} />
        <meta name="twitter:title" content={titleCard} />
        <meta name="twitter:description" content={descriptionCard} />
        <title>{titleCard}</title>
      </Helmet>
      <section className="businessCard_container">
        <div className="businessCard_wrapper">
          <div className="businessCard_wrapper-logo">
            <a href="/">
              <img src={logotype} alt="logo" />
            </a>
          </div>
          <div className="businessCard_wrapper-image">
            <img src={data.userPhoto} alt="user" />
          </div>
          <div className="businessCard_wrapper-description">
            <h2>
              {data.userName.firstName} {data.userName.lastName}
            </h2>
            <p>{data.position}</p>
          </div>
          <div className="businessCard_wrapper-contacts">
            <a href={data.phone.link}>{data.phone.text}</a>
            <a href={data.email.link}>{data.email.text}</a>
          </div>
          <div className="businessCard_wrapper-links">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={data.telegramLink}
            >
              Telegam
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={data.whatsappLink}
            >
              WhatsApp
            </a>
          </div>
          <div className="businessCard_wrapper-add">
            <div id="addToContact" onClick={() => handleDownload()}>
              <img src={arrow} alt="arrow" />
              <button>Add to contact</button>
            </div>
            <a href="/">tramptec.com</a>
          </div>
          <div className="businessCard_wrapper-code">
            <p>Link to the business card</p>
            <div className="code-card">
              <img src={data.qr} alt="qr-code" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BusinessCard;
