import React from "react";
import emailjs from "@emailjs/browser";
import {useTranslation} from 'react-i18next'
import { Link } from "react-router-dom";
const Form = () => {
  const {t} = useTranslation()
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(false);
  const form = React.useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_e3ez5fe",
        "template_xouk6fi",
        form.current,
        "MSKJGhKBnfjoAVFyK"
      )
      .then(
        (result) => {
          setEmail("");
          setMessage("");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail} className="form--container">
        <textarea
          name="user_message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          cols="30"
          rows="10"
        ></textarea>
        <p>{t('button.modal.email')}</p>
        <div className="form--inputs">
          <input
            name="user_email"
            required
            placeholder="Enter your email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            disabled={!isChecked}
            type="submit"
            value={t('button.submit')}
          />
        </div>
        <div className="checkbox-container">
          <input
            checked={isChecked}
            type="checkbox"
            onChange={(e) => setIsChecked(e.target.checked)}
          />

          <p>
           {t('button.modal.accept')}
           <Link to='/politics'> {t('button.modal.link')}</Link>
          </p>
        </div>
      </form>
    </>
  );
};

export default Form;
