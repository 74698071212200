import i18n from 'i18next'
import { floralDesign, customDesign, wavyDesign, bubblyDesign } from '../assets/images/CustomTypes'
import {roSheme,sqSheme, rcSheme, qSheme} from '../assets/icons/shemes/shemes'
import { photos } from '../assets/images/Types/index'
import { tableTypes } from '../assets/images/index'
import { set1, set2, set3 } from '../assets/images/Types/Sets/index'
import { dwg_2d } from '../assets/files/2D_DWG/index'
import { skp } from '../assets/files/SKP/index'
const round = <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
>
    <circle
        cx="20.5"
        cy="20.5"
        r="20.5"
        transform="rotate(90 20.5 20.5)"
        fill="#D2D2D2"
    />
</svg>

const square = <svg xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none">
    <rect width="35" height="35"
        fill="#D2D2D2"
    />
</svg>

const squircle = <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
>
    <path
        d="M18 36C25.8875 36 30.2725 36 33.1362 33.1362C36 30.2725 36 25.8875 36 18C36 10.1125 36 5.7275 33.1362 2.86375C30.2725 0 25.8875 0 18 0C10.1125 0 5.7275 0 2.86375 2.86375C0 5.7275 0 10.1125 0 18C0 25.8875 0 30.2725 2.86375 33.1362C5.7275 36 10.1125 36 18 36Z"
        fill="#D2D2D2"
    />
</svg>

const activeRound = <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
>
    <circle
        cx="20.5"
        cy="20.5"
        r="20.5"
        transform="rotate(90 20.5 20.5)"
        fill="#E1E051"
    />
</svg>

const activeSquare = <svg xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none">
    <rect width="35" height="35"
        fill="#E1E051"
    />
</svg>

const activeSquircle = <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
>
    <path
        d="M18 36C25.8875 36 30.2725 36 33.1362 33.1362C36 30.2725 36 25.8875 36 18C36 10.1125 36 5.7275 33.1362 2.86375C30.2725 0 25.8875 0 18 0C10.1125 0 5.7275 0 2.86375 2.86375C0 5.7275 0 10.1125 0 18C0 25.8875 0 30.2725 2.86375 33.1362C5.7275 36 10.1125 36 18 36Z"
        fill="#E1E051"
    />
</svg>

export const generateConstructorTypes = () => {
    const t = i18n.t.bind(i18n);

    return [
        {
            id: 1,
            name: t("constructor.form.title"),
            types: [
                {
                    id: 11,
                    name: t("constructor.form.types.squircle"),
                    param: 'squircle',
                    image: squircle,
                    activeImage: activeSquircle,
                },
                {
                    id: 12,
                    name: t("constructor.form.types.round"),
                    param: 'round',
                    image: round,
                    activeImage: activeRound,
                },
                {
                    id: 13,
                    name: t("constructor.form.types.square"),
                    param: 'square',
                    image: square,
                    activeImage: activeSquare,
                },
            ],
        },
    ];
};

export const generateSquircleItems = () => {
    const t = i18n.t.bind(i18n)

    return [
        {
            id: 111,
            name: t("constructor.protection.title"),
            types: [
                {
                    id: 1111,
                    name: t("constructor.protection.types.ring"),
                    param: 'ring',
                    image: round,
                    activeImage: activeRound,
                },
                {
                    id: 1112,
                    name: t("constructor.protection.types.mound"),
                    param: 'mound',
                    image: round,
                    activeImage: activeRound,
                },
            ],
        },
        {
            id: 112,
            name: t("constructor.light.title"),
            types: [
                {
                    id: 1211,
                    name: t("constructor.light.types.with"),
                    param: 'with',
                    image: round,
                    activeImage: activeRound,
                },
                {
                    id: 1212,
                    name: t("constructor.light.types.without"),
                    param: 'without',
                    image: round,
                    activeImage: activeRound,
                },
            ],
        },
        {
            id: 113,
            name: t("constructor.membranes.title"),
            types: [
                {
                    id: 1311,
                    name: t("constructor.membranes.types.rubber"),
                    image: activeRound,
                },
            ],
        },
    ];
}
export const generateRoundItems = () => {
    const t = i18n.t.bind(i18n)
    return [
        {
            id: 211,
            name: t("constructor.protection.title"),
            types: [
                {
                    id: 2111,
                    name: t("constructor.protection.types.ring"),
                    param: 'ring',
                    image: round,
                    activeImage: activeRound,
                },
                {
                    id: 2112,
                    name: t("constructor.protection.types.mound"),
                    param: 'mound',
                    image: round,
                    activeImage: activeRound,
                },
            ],
        },
        {
            id: 212,
            name: t("constructor.light.title"),
            types: [
                {
                    id: 2211,
                    name: t("constructor.light.types.with"),
                    param: 'with',
                    image: round,
                    activeImage: activeRound,
                },
                {
                    id: 2212,
                    name: t("constructor.light.types.without"),
                    param: 'without',
                    image: round,
                    activeImage: activeRound,
                },
            ],
        },
        {
            id: 213,
            name: t("constructor.membranes.title"),
            types: [
                {
                    id: 2311,
                    name: t("constructor.membranes.types.rubber"),
                    image: activeRound,
                },
            ],
        },
    ];
}

export const generateSquareItems = () => {
    const t = i18n.t.bind(i18n)
    return [
        {
            id: 311,
            name: t("constructor.protection.title"),
            types: [
                {
                    id: 3111,
                    name: t("constructor.protection.types.tiles"),
                    image: activeRound,
                },
            ],
        },
        {
            id: 321,
            name: t("constructor.light.title"),
            types: [
                {
                    id: 3211,
                    name: t("constructor.light.types.without"),
                    image: activeRound,
                },
            ],
        },
        {
            id: 331,
            name: t("constructor.membranes.title"),
            types: [
                {
                    id: 3311,
                    name: t("constructor.membranes.types.fabric"),
                    image: activeRound,
                },
            ],
        },
    ];
}

export const generateDesignTypes = () => {
    const t = i18n.t.bind(i18n);
    return [
        { id: 1, name: t('customDesign.types.wavy'), src: wavyDesign },
        { id: 2, name: t('customDesign.types.bubbly'), src: bubblyDesign },
        { id: 3, name: t('customDesign.types.floral'), src: floralDesign },
        { id: 4, name: t('customDesign.types.custom'), src: customDesign },
    ];
}

export const generateTrampoTypes = () => {
    const t = i18n.t.bind(i18n)
    return [
        {
            id: 1,
            title: t('trampoTypes.ring.title'),
            descr: t('trampoTypes.ring.text'),
            src: photos.squircleringwithoutrubber_sheet,
        },
        {
            id: 2,
            title: t('trampoTypes.tiles.title'),
            descr: t('trampoTypes.tiles.text'),
            src: photos.squaretileswithoutbelt_fabric,
        },
        {
            id: 3,
            title: t('trampoTypes.mound.title'),
            descr: t('trampoTypes.mound.text'),
            src: photos.squirclemoundwithoutrubber_sheet,
        },
    ];
}

export const generateCharacteristics = () => {
    const t = i18n.t.bind(i18n)
    return [
        {
            id: 1,
            src: tableTypes.squirlceItem,
            name: t('environment.squircle'),
            size: [{ id: 111, prop: "100x100", state: true }, { id: 112, prop: "150x150", state: true }],
            led: [{ id: 211, prop: t('environment.table.row2.prop1'), state: true }, { id: 212, prop: t('environment.table.row2.prop2'), state: true }],
            membrane: [{ id: 311, prop: t('environment.table.row3.prop1'), state: false }, { id: 312, prop: t('environment.table.row3.prop2'), state: true }],
            custom: [{ id: 411, prop: t('environment.table.row4.prop1'), state: true }, { id: 412, prop: t('environment.table.row4.prop2'), state: true }],
            protection: [{ id: 511, prop: t('environment.table.row5.prop1'), state: true }, { id: 512, prop: t('environment.table.row5.prop2'), state: true }, { id: 513, prop: t('environment.table.row5.prop3'), state: true }],
        },
        {
            id: 2,
            src: tableTypes.roundItem,
            name: t('environment.round'),
            size: [{ id: 121, prop: "d100", state: true }, { id: 122, prop: "d150", state: true }],
            led: [{ id: 221, prop: t('environment.table.row2.prop1'), state: true }, { id: 222, prop: t('environment.table.row2.prop2'), state: true }],
            membrane: [{ id: 321, prop: t('environment.table.row3.prop1'), state: false }, { id: 322, prop: t('environment.table.row3.prop2'), state: true }],
            custom: [{ id: 421, prop: t('environment.table.row4.prop1'), state: true }, { id: 422, prop: t('environment.table.row4.prop2'), state: true }],
            protection: [{ id: 521, prop: t('environment.table.row5.prop1'), state: true }, { id: 522, prop: t('environment.table.row5.prop2'), state: true }, { id: 523, prop: t('environment.table.row5.prop3'), state: true }]

        },
        {
            id: 3,
            src: tableTypes.squareItem,
            name: t('environment.square'),
            size: [{ id: 131, prop: "100x100", state: true }, { id: 132, prop: "150x150", state: true }, { id: 133, prop: "100x300", state: true }],
            led: [{ id: 231, prop: t('environment.table.row2.prop1'), state: false }, { id: 232, prop: t('environment.table.row2.prop2'), state: true }],
            membrane: [{ id: 331, prop: t('environment.table.row3.prop1'), state: true }, { id: 332, prop: t('environment.table.row3.prop2'), state: false }],
            custom: [{ id: 431, prop: t('environment.table.row4.prop1'), state: false }, { id: 432, prop: t('environment.table.row4.prop2'), state: false }],
            protection: [{ id: 531, prop: t('environment.table.row5.prop1'), state: false }, { id: 532, prop: t('environment.table.row5.prop2'), state: false }, { id: 533, prop: t('environment.table.row5.prop3'), state: true }]

        }
    ]
}

export const generateColumn = () => {
    const t = i18n.t.bind(i18n)
    return [
        {
            id: 100,
            name: t('environment.tableName'),
        },
        {
            id: 11,
            name: t('environment.table.row1.name'),
        },
        {
            id: 12,
            name: t('environment.table.row2.name'),
        },
        {
            id: 13,
            name: t('environment.table.row3.name'),
        },
        {
            id: 14,
            name: t('environment.table.row4.name'),
        },
        {
            id: 15,
            name: t('environment.table.row5.name'),
        },
    ]
}

export const generateMoundTypes = () => {
    const t = i18n.t.bind(i18n)
    return [
        { id: 0, title: "T 100S M", subTitle: t('catalog.in_mound.item1'), src: photos.squirclemoundwithoutrubber_sheet },
        { id: 1, title: "T 150S M", subTitle: t('catalog.in_mound.item1'), src: photos.bigR100R },
        { id: 2, title: "T 150R M", subTitle: t('catalog.in_mound.item2'), src: photos.bigR150 },
        { id: 3, title: "T 100R M", subTitle: t('catalog.in_mound.item2'), src: photos.roundmoundwithoutrubber_sheet, },
    ];
}

export const generateGroundTypes = () => {
    const t = i18n.t.bind(i18n)
    return [
        { id: 0, title: 'T 100S R', subTitle: t('catalog.in_ground.item1'), src: photos.squircleringwithoutrubber_sheet },
        { id: 1, title: 'T 150S R', subTitle: t('catalog.in_ground.item1'), src: photos.bigt100R },
        { id:2, title: "T 100R R", subTitle: t('catalog.in_ground.item2'), src: photos.roundringwithoutrubber_sheet },
        { id: 3, title: 'T 150R R', subTitle: t('catalog.in_ground.item2'), src: photos.bigCircle },
        { id: 4, title: 'T 150Q F', subTitle: t('catalog.in_ground.item3'), src: photos.squareBig },
        { id: 5, title: 'T 100Q F', subTitle: t('catalog.in_ground.item3'), src: photos.squaretileswithoutbelt_fabric },
        { id: 6, title: 'T 300Q F', subTitle: t('catalog.in_ground.item4'), src: photos.rectangle },
    ];
}

export const generateSetTypes = () => {
    const t = i18n.t.bind(i18n)
    return [
        { id: 0, title: "T 3S", subTitle: t('catalog.sets.item1'), src: set1 },
        { id: 1, title: "T 3R", subTitle: t('catalog.sets.item2'), src: set2 },
        { id: 2, title: "T 3Q", subTitle: t('catalog.sets.item3'), src: set3 },
    ];
}

export const generateInGround = () => {
    const t = i18n.t.bind(i18n)
    return [
        {
            id: 100,
            title: t('trompolinos.ground.squircle.item1.title'),
            model: 'T 100S R.NL',
            ledModel: 'T 100S R.L',
            srcBase: photos.squircleringwithoutrubber_sheet,
            srcActive: photos.squircleringwithrubber_sheet,
            scheme: sqSheme,
            config: [
                {
                    id: 1,
                    name: t('trompolinos.types.protection.title'),
                    types: [
                        { id: 11, name: t('trompolinos.types.protection.prop1') },
                    ],
                },
                {
                    id: 2,
                    name: t('trompolinos.types.light.title'),
                    types: [
                        { id: 21, name: t('trompolinos.types.light.prop1'), state: false },
                        { id: 22, name: t('trompolinos.types.light.prop2'), state: true },
                    ],
                },
                {
                    id: 3,
                    name: t('trompolinos.types.membranes.title'),
                    types: [
                        { id: 31, name: t('trompolinos.types.membranes.prop2') },
                    ],
                },
                {
                    id: 4,
                    name: t('trompolinos.types.custom.title'),
                    types: [
                        { id: 41, name: t('trompolinos.types.custom.prop1') },
                        { id: 42, name: t('trompolinos.types.custom.prop2') },
                    ],
                },
            ],
            details: [
                { id: 1010, name: t('trompolinos.ground.squircle.item1.details.steel') },
                { id: 1011, name: t('trompolinos.ground.squircle.item1.details.weight') },
                { id: 1012, name: t('trompolinos.ground.squircle.item1.details.height') },
                { id: 1013, name: t('trompolinos.ground.squircle.item1.details.area') },
                { id: 1014, name: t('trompolinos.ground.squircle.item1.details.text') },
            ],
            links: [
                { id: 1, name: "2D | DWG", state: true, file: dwg_2d.T100SR_2D, fileName: 'T 100 S R.dwg' },
                { id: 2, name: "3D | DWG", state: false, file: '' },
                { id: 3, name: "SKP", state: true, file: skp.T100SR_SKP, fileName: 'T 100 S R.skp' },
                { id: 4, name: t('downloads.product'), state: false, file: '' },
                { id: 5, name: t('downloads.instruction'), state: false, file: '' },
            ]
        },
        {
            id: 101,
            title: t('trompolinos.ground.squircle.item2.title'),
            model: 'T 150S R.NL',
            ledModel: 'T 150S R.L',
            srcBase: photos.bigt100R,
            srcActive: photos.bigt100RLed,
            scheme: sqSheme,
            config: [
                {
                    id: 1,
                    name: t('trompolinos.types.protection.title'),
                    types: [
                        { id: 11, name: t('trompolinos.types.protection.prop1') },
                    ],
                },
                {
                    id: 2,
                    name: t('trompolinos.types.light.title'),
                    types: [
                        { id: 22, name: t('trompolinos.types.light.prop1'), state: false },
                        { id: 23, name: t('trompolinos.types.light.prop2'), state: true },
                    ],
                },
                {
                    id: 3,
                    name: t('trompolinos.types.membranes.title'),
                    types: [
                        { id: 31, name: t('trompolinos.types.membranes.prop2') },
                    ],
                },
                {
                    id: 4,
                    name: t('trompolinos.types.custom.title'),
                    types: [
                        { id: 41, name: t('trompolinos.types.custom.prop1') },
                        { id: 42, name: t('trompolinos.types.custom.prop2') },
                    ],
                },
            ],
            details: [
                { id: 1110, name: t('trompolinos.ground.squircle.item2.details.steel') },
                { id: 1111, name: t('trompolinos.ground.squircle.item2.details.weight') },
                { id: 1112, name: t('trompolinos.ground.squircle.item2.details.height') },
                { id: 1113, name: t('trompolinos.ground.squircle.item2.details.area') },
                { id: 1114, name: t('trompolinos.ground.squircle.item2.details.text') },
            ],
            links: [
                { id: 1, name: "2D | DWG", state: true, file: dwg_2d.T150SR_2D, fileName: 'T 150S R.dwg' },
                { id: 2, name: "3D | DWG", state: false, file: '' },
                { id: 3, name: "SKP", state: true, file: skp.T150SR_SKP, fileName: 'T 150S R.skp' },
                { id: 4, name: t('downloads.product'), state: false, file: '' },
                { id: 5, name: t('downloads.instruction'), state: false, file: '' },
            ]
        },
        {
            id: 102,
            title: t('trompolinos.ground.round.item1.title'),
            model: 'T 100R R.NL',
            ledModel: 'T 100R R.L',
            srcBase: photos.roundringwithoutrubber_sheet,
            srcActive: photos.roundringwithrubber_sheet,
            scheme: roSheme,
            config: [
                {
                    id: 1,
                    name: t('trompolinos.types.protection.title'),
                    types: [
                        { id: 11, name: t('trompolinos.types.protection.prop1') },
                    ],
                },
                {
                    id: 2,
                    name: t('trompolinos.types.light.title'),
                    types: [
                        { id: 21, name: t('trompolinos.types.light.prop1'), state: false },
                        { id: 22, name: t('trompolinos.types.light.prop2'), state: true },
                    ],
                },
                {
                    id: 3,
                    name: t('trompolinos.types.membranes.title'),
                    types: [
                        { id: 31, name: t('trompolinos.types.membranes.prop2') },
                    ],
                },
                {
                    id: 4,
                    name: t('trompolinos.types.custom.title'),
                    types: [
                        { id: 41, name: t('trompolinos.types.custom.prop1') },
                        { id: 42, name: t('trompolinos.types.custom.prop2') },
                    ],
                },
            ],
            details: [
                { id: 1210, name: t('trompolinos.ground.round.item1.details.steel') },
                { id: 1211, name: t('trompolinos.ground.round.item1.details.weight') },
                { id: 1212, name: t('trompolinos.ground.round.item1.details.height') },
                { id: 1213, name: t('trompolinos.ground.round.item1.details.area') },
                { id: 1214, name: t('trompolinos.ground.round.item1.details.text') },
            ],
            links: [
                { id: 1, name: "2D | DWG", state: true, file: dwg_2d.T100RR_2D, fileName: 'T 100R R.dwg' },
                { id: 2, name: "3D | DWG", state: false, file: skp.T100RR_SKP, fileName: 'T 100R R.skp' },
                { id: 3, name: "SKP", state: true, file: '' },
                { id: 4, name: t('downloads.product'), state: false, file: '' },
                { id: 5, name: t('downloads.instruction'), state: false, file: '' },
            ]
        },
        {
            id: 103,
            title: t('trompolinos.ground.round.item2.title'),
            model: 'T 150R R.NL',
            ledModel: 'T 150R R.L',
            srcBase: photos.bigCircle,
            srcActive: photos.bigCircleLed,
            scheme: roSheme,
            config: [
                {
                    id: 1,
                    name: t('trompolinos.types.protection.title'),
                    types: [
                        { id: 11, name: t('trompolinos.types.protection.prop1') }
                    ],
                },
                {
                    id: 2,
                    name: t('trompolinos.types.light.title'),
                    types: [
                        { id: 21, name: t('trompolinos.types.light.prop1'), state: false },
                        { id: 22, name: t('trompolinos.types.light.prop2'), state: true },
                    ],
                },
                {
                    id: 3,
                    name: t('trompolinos.types.membranes.title'),
                    types: [
                        { id: 31, name: t('trompolinos.types.membranes.prop2') },
                    ],
                },
                {
                    id: 4,
                    name: t('trompolinos.types.custom.title'),
                    types: [
                        { id: 41, name: t('trompolinos.types.custom.prop1') },
                        { id: 42, name: t('trompolinos.types.custom.prop2') },
                    ],
                },
            ],
            details: [
                { id: 1310, name: t('trompolinos.ground.round.item2.details.steel') },
                { id: 1311, name: t('trompolinos.ground.round.item2.details.weight') },
                { id: 1312, name: t('trompolinos.ground.round.item2.details.height') },
                { id: 1313, name: t('trompolinos.ground.round.item2.details.area') },
                { id: 1314, name: t('trompolinos.ground.round.item2.details.text') },
            ],
            links: [
                { id: 1, name: "2D | DWG", state: true, file: dwg_2d.T150RR_2D, fileName: 'T 150R R.dwg' },
                { id: 2, name: "3D | DWG", state: false, file: '' },
                { id: 3, name: "SKP", state: true, file: skp.T150RR_SKP, fileName: 'T 150R R.skp' },
                { id: 4, name: t('downloads.product'), state: false, file: '' },
                { id: 5, name: t('downloads.instruction'), state: false, file: '' },
            ]
        },
        {
            id: 104,
            title: t('trompolinos.ground.square.item1.title'),
            model: 'T 150Q F.NL',
            srcBase: photos.squareBig,
            scheme: qSheme,
            config: [
                {
                    id: 1,
                    name: t('trompolinos.types.protection.title'),
                    types: [
                        { id: 11, name: t('trompolinos.types.protection.prop1') },
                    ],
                },
                {
                    id: 2,
                    name: t('trompolinos.types.light.title'),
                    types: [
                        { id: 22, name: t('trompolinos.types.light.prop2') },
                    ],
                },
                {
                    id: 3,
                    name: t('trompolinos.types.membranes.title'),
                    types: [
                        { id: 31, name: t('trompolinos.types.membranes.prop1') },
                    ],
                },
                {
                    id: 4,
                    name: t('trompolinos.types.custom.title'),
                    types: [
                        { id: 41, name: t('trompolinos.types.custom.prop1') },
                        { id: 42, name: t('trompolinos.types.custom.prop2') },
                    ],
                },
            ],
            details: [
                { id: 1410, name: t('trompolinos.ground.square.item1.details.steel') },
                { id: 1411, name: t('trompolinos.ground.square.item1.details.weight') },
                { id: 1412, name: t('trompolinos.ground.square.item1.details.height') },
                { id: 1413, name: t('trompolinos.ground.square.item1.details.area') },
                { id: 1414, name: t('trompolinos.ground.square.item1.details.text') },
            ],
            links: [
                { id: 1, name: "2D | DWG", state: true, file: dwg_2d.T150QF_2D, fileName: 'T 150Q F.dwg' },
                { id: 2, name: "3D | DWG", state: false, },
                { id: 3, name: "SKP", state: true, ffile: skp.T150QF_SKP, fileName: 'T 150Q F.skp' },
                { id: 4, name: t('downloads.product'), state: false, file: '' },
                { id: 5, name: t('downloads.instruction'), state: false, file: '' },
            ]
        },
        {
            id: 105,
            title: t('trompolinos.ground.square.item2.title'),
            model: 'T 100Q F.NL',
            srcBase: photos.squaretileswithoutbelt_fabric,
            scheme: qSheme,
            config: [
                {
                    id: 1,
                    name: t('trompolinos.types.protection.title'),
                    types: [
                        { id: 11, name: t('trompolinos.types.protection.prop1') }
                    ],
                },
                {
                    id: 2,
                    name: t('trompolinos.types.light.title'),
                    types: [
                        { id: 22, name: t('trompolinos.types.light.prop2') },
                    ],
                },
                {
                    id: 3,
                    name: t('trompolinos.types.membranes.title'),
                    types: [
                        { id: 31, name: t('trompolinos.types.membranes.prop1') },
                    ],
                },
                {
                    id: 4,
                    name: t('trompolinos.types.custom.title'),
                    types: [
                        { id: 41, name: t('trompolinos.types.custom.prop1') },
                        { id: 42, name: t('trompolinos.types.custom.prop2') },
                    ],
                },
            ],
            details: [
                { id: 1410, name: t('trompolinos.ground.square.item2.details.steel') },
                { id: 1411, name: t('trompolinos.ground.square.item2.details.weight') },
                { id: 1412, name: t('trompolinos.ground.square.item2.details.height') },
                { id: 1413, name: t('trompolinos.ground.square.item2.details.area') },
                { id: 1414, name: t('trompolinos.ground.square.item2.details.text') },
            ],
            links: [
                { id: 1, name: "2D | DWG", state: true, file: dwg_2d.T100QF_2D, fileName: 'T 100Q F.dwg' },
                { id: 2, name: "3D | DWG", state: false, file: '' },
                { id: 3, name: "SKP", state: true, file: skp.T100QF_SKP, fileName: 'T 100Q F.skp' },
                { id: 4, name: t('downloads.product'), state: false, file: '' },
                { id: 5, name: t('downloads.instruction'), state: false, file: '' },
            ]
        },
        {
            id: 106,
            title: t('trompolinos.ground.rectangle.item1.title'),
            model: 'T 300Q F.NL',
            srcBase: photos.rectangle,
            scheme: rcSheme,
            config: [
                {
                    id: 1,
                    name: t('trompolinos.types.protection.title'),
                    types: [
                        { id: 11, name: t('trompolinos.types.protection.prop1') },
                    ],
                },
                {
                    id: 2,
                    name: t('trompolinos.types.light.title'),
                    types: [
                        { id: 22, name: t('trompolinos.types.light.prop2') },
                    ],
                },
                {
                    id: 3,
                    name: t('trompolinos.types.membranes.title'),
                    types: [
                        { id: 31, name: t('trompolinos.types.membranes.prop1') }
                    ],
                },
                {
                    id: 4,
                    name: t('trompolinos.types.custom.title'),
                    types: [
                        { id: 41, name: t('trompolinos.types.custom.prop1') },
                        { id: 42, name: t('trompolinos.types.custom.prop2') },
                    ],
                },
            ],
            details: [
                { id: 1610, name: t('trompolinos.ground.squircle.item1.details.steel') },
                { id: 1611, name: t('trompolinos.ground.squircle.item1.details.weight') },
                { id: 1612, name: t('trompolinos.ground.squircle.item1.details.height') },
                { id: 1613, name: t('trompolinos.ground.squircle.item1.details.area') },
                { id: 1614, name: t('trompolinos.ground.squircle.item1.details.text') },
            ],
            links: [
                { id: 1, name: "2D | DWG", state: true, file: dwg_2d.T300QF_2D, fileName: 'T 300Q F.dwg' },
                { id: 2, name: "3D | DWG", state: false, file: '' },
                { id: 3, name: "SKP", state: true, file: skp.T300QF_SKP, fileName: 'T 300Q F.skp' },
                { id: 4, name: t('downloads.product'), state: false, file: '' },
                { id: 5, name: t('downloads.instruction'), state: false, file: '' },
            ]
        },
    ]

}
export const generateInMound = () => {
    const t = i18n.t.bind(i18n)
    return [
        {
            id: 200,
            title: t('trompolinos.mound.squircle.item1.title'),
            model: 'T 100S M.NL',
            ledModel: 'T 100S M.L',
            srcBase: photos.squirclemoundwithoutrubber_sheet,
            srcActive: photos.squirclemoundwithrubber_sheet,
            scheme: sqSheme,
            config: [
                {
                    id: 1,
                    name: t('trompolinos.types.protection.title'),
                    types: [
                        { id: 11, name: t('trompolinos.types.protection.prop1') }
                    ],
                },
                {
                    id: 2,
                    name: t('trompolinos.types.light.title'),
                    types: [
                        { id: 21, name: t('trompolinos.types.light.prop1'), state: false },
                        { id: 22, name: t('trompolinos.types.light.prop2'), state: true },
                    ],
                },
                {
                    id: 3,
                    name: t('trompolinos.types.membranes.title'),
                    types: [
                        { id: 31, name: t('trompolinos.types.membranes.prop2') },
                    ],
                },
                {
                    id: 4,
                    name: t('trompolinos.types.custom.title'),
                    types: [
                        { id: 41, name: t('trompolinos.types.custom.prop1') },
                        { id: 42, name: t('trompolinos.types.custom.prop2') },
                    ],
                },
            ],
            details: [
                { id: 2010, name: t('trompolinos.mound.squircle.item1.details.steel') },
                { id: 2011, name: t('trompolinos.mound.squircle.item1.details.weight') },
                { id: 2012, name: t('trompolinos.mound.squircle.item1.details.height') },
                { id: 2013, name: t('trompolinos.mound.squircle.item1.details.area') },
                { id: 2014, name: t('trompolinos.mound.squircle.item1.details.text') },
            ],
            links: [
                { id: 1, name: "2D | DWG", state: true, file: dwg_2d.T100SM_2D, fileName: 'T 100S M.dwg' },
                { id: 2, name: "3D | DWG", state: false, file: '' },
                { id: 3, name: "SKP", state: true, file: skp.T100SM_SKP, fileName: 'T 100S M.skp' },
                { id: 4, name: t('downloads.product'), state: false, file: '' },
                { id: 5, name: t('downloads.instruction'), state: false, file: '' },
            ]
        },
        {
            id: 201,
            title: t('trompolinos.mound.squircle.item2.title'),
            model: 'T 150S M.NL',
            ledModel: 'T 150S M.L',
            srcBase: photos.bigR100R,
            srcActive: photos.bigS100Led,
            scheme: sqSheme,
            config: [
                {
                    id: 1,
                    name: t('trompolinos.types.protection.title'),
                    types: [
                        { id: 11, name: t('trompolinos.types.protection.prop1') },
                    ],
                },
                {
                    id: 2,
                    name: t('trompolinos.types.light.title'),
                    types: [
                        { id: 21, name: t('trompolinos.types.light.prop1'), state: false },
                        { id: 22, name: t('trompolinos.types.light.prop2'), state: true },
                    ],
                },
                {
                    id: 3,
                    name: t('trompolinos.types.membranes.title'),
                    types: [
                        { id: 31, name: t('trompolinos.types.membranes.prop2') },
                    ],
                },
                {
                    id: 4,
                    name: t('trompolinos.types.custom.title'),
                    types: [
                        { id: 41, name: t('trompolinos.types.custom.prop1') },
                        { id: 42, name: t('trompolinos.types.custom.prop2') },
                    ],
                },
            ],
            details: [
                { id: 2110, name: t('trompolinos.mound.squircle.item2.details.steel') },
                { id: 2111, name: t('trompolinos.mound.squircle.item2.details.weight') },
                { id: 2112, name: t('trompolinos.mound.squircle.item2.details.height') },
                { id: 2113, name: t('trompolinos.mound.squircle.item2.details.area') },
                { id: 2114, name: t('trompolinos.mound.squircle.item2.details.text') },
            ],
            links: [
                { id: 1, name: "2D | DWG", state: true, file: dwg_2d.T150SM_2D, fileName: 'T 150S M.dwg' },
                { id: 2, name: "3D | DWG", state: false, file: '' },
                { id: 3, name: "SKP", state: true, file: skp.T150SM_SKP, fileName: 'T 150S M.skp' },
                { id: 4, name: t('downloads.product'), state: false, file: '' },
                { id: 5, name: t('downloads.instruction'), state: false, file: '' },
            ]
        },
        {
            id: 202,
            title: t('trompolinos.mound.round.item1.title'),
            model: 'T 150R M.NL',
            ledModel: 'T 150R M.L',
            srcBase: photos.bigR150,
            srcActive: photos.bigR150Led,
            scheme: roSheme,
            config: [
                {
                    id: 1,
                    name: t('trompolinos.types.protection.title'),
                    types: [
                        { id: 11, name: t('trompolinos.types.protection.prop1') },
                    ],
                },
                {
                    id: 2,
                    name: t('trompolinos.types.light.title'),
                    types: [
                        { id: 21, name: t('trompolinos.types.light.prop1'), state: false },
                        { id: 22, name: t('trompolinos.types.light.prop2'), state: true },
                    ],
                },
                {
                    id: 3,
                    name: t('trompolinos.types.membranes.title'),
                    types: [
                        { id: 31, name: t('trompolinos.types.membranes.prop2') },
                    ],
                },
                {
                    id: 4,
                    name: t('trompolinos.types.custom.title'),
                    types: [
                        { id: 41, name: t('trompolinos.types.custom.prop1') },
                        { id: 42, name: t('trompolinos.types.custom.prop2') },
                    ],
                },
            ],
            details: [
                { id: 2210, name: t('trompolinos.mound.round.item1.details.steel') },
                { id: 2211, name: t('trompolinos.mound.round.item1.details.weight') },
                { id: 2212, name: t('trompolinos.mound.round.item1.details.height') },
                { id: 2213, name: t('trompolinos.mound.round.item1.details.area') },
                { id: 2214, name: t('trompolinos.mound.round.item1.details.text') },
            ],
            links: [
                { id: 1, name: "2D | DWG", state: true, file: dwg_2d.T150RM_2D, fileName: 'T 150R M.dwg' },
                { id: 2, name: "3D | DWG", state: false, file: '' },
                { id: 3, name: "SKP", state: true, file: skp.T150RM_SKP, fileName: 'T 150R M.skp' },
                { id: 4, name: t('downloads.product'), state: false, file: '' },
                { id: 5, name: t('downloads.instruction'), state: false, file: '' },
            ]
        },
        {
            id: 203,
            title: t('trompolinos.mound.round.item2.title'),
            model: 'T 100R M.NL',
            ledModel: 'T 100R M.L',
            srcBase: photos.roundmoundwithoutrubber_sheet,
            srcActive: photos.roundmoundwithrubber_sheet,
            scheme:roSheme,
            config: [
                {
                    id: 1,
                    name: t('trompolinos.types.protection.title'),
                    types: [
                        { id: 11, name: t('trompolinos.types.protection.prop1') },
                    ],
                },
                {
                    id: 2,
                    name: t('trompolinos.types.light.title'),
                    types: [
                        { id: 21, name: t('trompolinos.types.light.prop1'), state: false },
                        { id: 22, name: t('trompolinos.types.light.prop2'), state: true },
                    ],
                },
                {
                    id: 3,
                    name: t('trompolinos.types.membranes.title'),
                    types: [
                        { id: 31, name: t('trompolinos.types.membranes.prop2') },
                    ],
                },
                {
                    id: 4,
                    name: t('trompolinos.types.custom.title'),
                    types: [
                        { id: 41, name: t('trompolinos.types.custom.prop1') },
                        { id: 42, name: t('trompolinos.types.custom.prop2') },
                    ],
                },
            ],
            details: [
                { id: 2310, name: t('trompolinos.mound.round.item1.details.steel') },
                { id: 2311, name: t('trompolinos.mound.round.item1.details.weight') },
                { id: 2312, name: t('trompolinos.mound.round.item1.details.height') },
                { id: 2313, name: t('trompolinos.mound.round.item1.details.area') },
                { id: 2314, name: t('trompolinos.mound.round.item1.details.text') },
            ],
            links: [
                { id: 1, name: "2D | DWG", state: true, file: dwg_2d.T100RM_2D, fileName: 'T 100R M.dwg' },
                { id: 2, name: "3D | DWG", state: false, file: '' },
                { id: 3, name: "SKP", state: true, file: skp.T100RM_SKP, fileName: 'T 100R M.skp' },
                { id: 4, name: t('downloads.product'), state: false, file: '' },
                { id: 5, name: t('downloads.instruction'), state: false, file: '' },
            ]
        },
    ]

}

export const generateInSet = () => {
    const t = i18n.t.bind(i18n)
    return [
        {
            id: 300,
            title: t('trompolinos.sets.squircle.item1.title'),
            model: "T 3S",
            src: set1,
            scheme: sqSheme,
            config: [
                {
                    id: 1,
                    name: t('trompolinos.types.protection.title'),
                    types: [
                        { id: 11, name: t('trompolinos.types.protection.prop1') },
                    ],
                },
                {
                    id: 2,
                    name: t('trompolinos.types.light.title'),
                    types: [
                        // { id: 21, name: t('trompolinos.types.light.prop1'), state: false },
                        { id: 22, name: t('trompolinos.types.light.prop2'), state: true },
                    ],
                },
                {
                    id: 3,
                    name: t('trompolinos.types.membranes.title'),
                    types: [
                        { id: 31, name: t('trompolinos.types.membranes.prop2') },
                    ],
                },
                {
                    id: 4,
                    name: t('trompolinos.types.custom.title'),
                    types: [
                        { id: 41, name: t('trompolinos.types.custom.prop1') },
                        { id: 42, name: t('trompolinos.types.custom.prop2') },
                    ],
                },
            ],
            details: [
                { id: 3010, name: t('trompolinos.sets.squircle.item1.details.steel') },
                { id: 3011, name: t('trompolinos.sets.squircle.item1.details.weight') },
                { id: 3012, name: t('trompolinos.sets.squircle.item1.details.height') },
                { id: 3013, name: t('trompolinos.sets.squircle.item1.details.area') },
                { id: 3014, name: t('trompolinos.sets.squircle.item1.details.text') },
            ],
            links: [
                { id: 1, name: "2D | DWG", state: true, file: dwg_2d.T3S_2D, fileName: 'Set 3S.dwg' },
                { id: 2, name: "3D | DWG", state: false, file: '' },
                { id: 3, name: "SKP", state: true, file: skp.T3S_SKP, fileName: 'T 3S.skp' },
                { id: 4, name: t('downloads.product'), state: false, file: '' },
                { id: 5, name: t('downloads.instruction'), state: false, file: '' },
            ]
        },
        {
            id: 301,
            title: t('trompolinos.sets.round.item1.title'),
            model: "T 3R",
            src: set2,
            scheme: roSheme,
            config: [
                {
                    id: 1,
                    name: t('trompolinos.types.protection.title'),
                    types: [
                        { id: 11, name: t('trompolinos.types.protection.prop1'), },
                    ],
                },
                {
                    id: 2,
                    name: t('trompolinos.types.light.title'),
                    types: [
                        // { id: 21, name: t('trompolinos.types.light.prop1'), state: false },
                        { id: 22, name: t('trompolinos.types.light.prop2'), state: true },
                    ],
                },
                {
                    id: 3,
                    name: t('trompolinos.types.membranes.title'),
                    types: [
                        { id: 31, name: t('trompolinos.types.membranes.prop2') },
                    ],
                },
                {
                    id: 4,
                    name: t('trompolinos.types.custom.title'),
                    types: [
                        { id: 41, name: t('trompolinos.types.custom.prop1') },
                        { id: 42, name: t('trompolinos.types.custom.prop2') },
                    ],
                },
            ],
            details: [
                { id: 3110, name: t('trompolinos.sets.round.item1.details.steel') },
                { id: 3111, name: t('trompolinos.sets.round.item1.details.weight') },
                { id: 3112, name: t('trompolinos.sets.round.item1.details.height') },
                { id: 3113, name: t('trompolinos.sets.round.item1.details.area') },
                { id: 3114, name: t('trompolinos.sets.round.item1.details.text') },
            ],
            links: [
                { id: 1, name: "2D | DWG", state: true, file: dwg_2d.T3R_2D, fileName: 'Set 3R.dwg' },
                { id: 2, name: "3D | DWG", state: false, file: '' },
                { id: 3, name: "SKP", state: true, file: skp.T3R_SKP, fileName: 'T 3R.skp' },
                { id: 4, name: t('downloads.product'), state: false, file: '' },
                { id: 5, name: t('downloads.instruction'), state: false, file: '' },
            ],
        },
        {
            id: 302,
            title: t('trompolinos.sets.square.item1.title'),
            model: "T 3Q",
            src: set3,
            scheme: qSheme,
            config: [
                {
                    id: 1,
                    name: t('trompolinos.types.protection.title'),
                    types: [
                        { id: 11, name: t('trompolinos.types.protection.prop1') },
                    ],
                },
                {
                    id: 2,
                    name: t('trompolinos.types.light.title'),
                    types: [
                        // { id: 21, name: t('trompolinos.types.light.prop1'), state: false },
                        { id: 22, name: t('trompolinos.types.light.prop2'), state: true },
                    ],
                },
                {
                    id: 3,
                    name: t('trompolinos.types.membranes.title'),
                    types: [
                        { id: 31, name: t('trompolinos.types.membranes.prop1') },
                    ],
                },
                {
                    id: 4,
                    name: t('trompolinos.types.custom.title'),
                    types: [
                        { id: 41, name: t('trompolinos.types.custom.prop1') },
                        { id: 42, name: t('trompolinos.types.custom.prop2') },
                    ],
                },
            ],
            details: [
                { id: 3210, name: t('trompolinos.sets.square.item1.details.steel') },
                { id: 3211, name: t('trompolinos.sets.square.item1.details.weight') },
                { id: 3212, name: t('trompolinos.sets.square.item1.details.height') },
                { id: 3213, name: t('trompolinos.sets.square.item1.details.area') },
                { id: 3214, name: t('trompolinos.sets.square.item1.details.text') },
            ],
            links: [
                { id: 1, name: "2D | DWG", state: true, file: dwg_2d.T3Q_2D, fileName: 'Set 3Q.dwg' },
                { id: 2, name: "3D | DWG", state: false, file: '' },
                { id: 3, name: "SKP", state: true, file: skp.T3Q_SKP, fileName: 'T 3Q.skp' },
                { id: 4, name: t('downloads.product'), state: false, file: '' },
                { id: 5, name: t('downloads.instruction'), state: false, file: '' },
            ]
        },
    ]

}