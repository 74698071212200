import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { handleDownloadPdf } from "../shared/functions";
const Header = () => {
  // React.useEffect(() => {
  //   if (window.location.hostname === 'tramptec.ru') {
  //     setSelectedLanguage('ru')
  //   } else {
  //     setSelectedLanguage('en')
  //   }
  //   }, [])
  const { t, i18n } = useTranslation();
  const languageRef = React.useRef(null);
  const burgerRef = React.useRef(null);
  const smallMenuLang = React.useRef(null);
  const prevScroll = React.useRef(0);
  const [selectedLanguage, setSelectedLanguage] = React.useState(i18n.language);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isBurgerOpen, setIsBurgerOpen] = React.useState(false);
  // const [prevScroll, setPrevScroll] = React.useState(0);
  const languages = [
    { id: 1, name: "ru" },
    { id: 2, name: "en" },
    { id: 3, name: "tr" },
    { id: 4, name: "de" },
    { id: 5, name: "es" },
    { id: 6, name: "fr" },
    // { id: 6, name: "ar" },
  ];
  const handleLanguage = (el) => {
    setSelectedLanguage(el.name);
    i18n.changeLanguage(el.name);
  };
  const handleCleaner = () => {
    window.scrollTo(0, 0);
  };
  const handleCloseLang = (e) => {
    if (languageRef.current !== null) {
      if (!languageRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    }
  };

  const handleCloseSmallLang = (e) => {
    if (smallMenuLang.current !== null) {
      if (!smallMenuLang.current.contains(e.target)) {
        setIsOpen(false);
      }
    }
  };
  React.useEffect(() => {
    const handleHeader = (e) => {
      let currentScroll = window.scrollY;
      if (currentScroll > 0) {
        if (prevScroll.current > currentScroll) {
          document.querySelector("header").classList.add("sticky");
        } else {
          document.querySelector("header").classList.remove("sticky");
        }
      } else if (currentScroll < 100) {
        document.querySelector("header").classList.remove("sticky");
      }
      prevScroll.current = currentScroll;
    };
    window.addEventListener("scroll", handleHeader);
    return () => {
      window.removeEventListener("scroll", handleHeader);
      window.removeEventListener("scroll", handleCloseSmallLang);
    };
  }, []);
  return (
    <header>
      <div className="header--logo" onClick={() => handleCleaner()}>
        <Link to="/">
          {/* <img src={logoTramptec} alt="tramptc logo" /> */}
        </Link>
      </div>
      <nav>
        <ul className="nav--items">
          <li>
            <Link
              // to={location.pathname === "/catalog" ||
              // location.pathname === "/contacts" ||
              // location.pathname === "/catalog/squircle" ||
              // location.pathname === '/catalog/round'
              //   ? "/"
              //   : "/catalog"}
              to="/catalog"
            >
              {/* {location.pathname === "/catalog" ||
              location.pathname === "/contacts" ||
              location.pathname === "/catalog/squircle" ||
              location.pathname === '/catalog/round'
                ? "home"
                : "catalog"} */}
              {t("header.catalog")}
            </Link>
          </li>
          <li>
            <Link
              // to={location.pathname === "/contacts" ? "/catalog" : "/contacts"}
              to="/contacts"
            >
              {/* {location.pathname === "/contacts" ? "catalog" : "contacts"} */}
              {t("header.contacts")}
            </Link>
          </li>
          <li className="download" onClick={handleDownloadPdf}>{t("header.download")}</li>
          <li>
            <div
              ref={languageRef}
              className="language"
              onClick={() => setIsOpen(!isOpen)}
            >
              <div className="language--select">
                <p>{selectedLanguage} </p>
                <div
                  className={
                    isOpen
                      ? "language--select__arrow-active"
                      : "language--select__arrow"
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M0.349976 4.25676C0.349976 3.90316 0.489976 3.56316 0.755976 3.29116C1.30198 2.76076 2.19798 2.76076 2.74398 3.29116L6.99997 7.41196L11.256 3.27756C11.802 2.74716 12.698 2.74716 13.244 3.27756C13.79 3.80796 13.79 4.67836 13.244 5.20876L7.99397 10.3088C7.72797 10.5672 7.37797 10.7032 6.99997 10.7032C6.62197 10.7032 6.27198 10.5536 6.00598 10.3088L0.755976 5.22236C0.489976 4.95036 0.349976 4.59676 0.349976 4.25676Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
              {isOpen && (
                <div className="language--items">
                  {languages.map((el) => (
                    <div
                      className="language--item"
                      onClick={(event) => handleLanguage(el, event)}
                      key={el.id}
                    >
                      {selectedLanguage === el.name ? null : (
                        <span>{el.name}</span>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </li>
        </ul>
        <div ref={burgerRef} className="burger--menu">
          <div
            ref={smallMenuLang}
            className="burger-language"
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="language--select">
              <p>{selectedLanguage} </p>
              <div
                className={
                  isOpen
                    ? "language--select__arrow-active"
                    : "language--select__arrow"
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M0.349976 4.25676C0.349976 3.90316 0.489976 3.56316 0.755976 3.29116C1.30198 2.76076 2.19798 2.76076 2.74398 3.29116L6.99997 7.41196L11.256 3.27756C11.802 2.74716 12.698 2.74716 13.244 3.27756C13.79 3.80796 13.79 4.67836 13.244 5.20876L7.99397 10.3088C7.72797 10.5672 7.37797 10.7032 6.99997 10.7032C6.62197 10.7032 6.27198 10.5536 6.00598 10.3088L0.755976 5.22236C0.489976 4.95036 0.349976 4.59676 0.349976 4.25676Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
            {isOpen && (
              <div className="burger-language_items">
                {languages.map((el) => (
                  <div
                    className={
                      selectedLanguage === el.name
                        ? "selected burger-language--item"
                        : "burger-language--item"
                    }
                    onClick={(event) => handleLanguage(el, event)}
                    key={el.id}
                  >
                    {selectedLanguage === el.name ? null : (
                      <span>{el.name}</span>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="burger--button" onClick={() => setIsBurgerOpen(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="20"
              viewBox="0 0 2 10"
              fill="none"
            >
              <path d="M1 0V10" stroke="black" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="20"
              viewBox="0 0 2 10"
              fill="none"
            >
              <path d="M1 0V10" stroke="black" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="20"
              viewBox="0 0 2 10"
              fill="none"
            >
              <path d="M1 0V10" stroke="black" />
            </svg>
          </div>
          {isBurgerOpen && (
            <div className="burger--container">
              <div
                className="exit--container"
                onClick={() => setIsBurgerOpen(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path d="M1 1L9 9" stroke="black" strokeLinecap="round" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path d="M9 1L1 9" stroke="black" strokeLinecap="round" />
                </svg>
              </div>
              <ul className="burger--items">
                <li>
                  <Link
                    // to={location.pathname === "/catalog" ||
                    // location.pathname === "/contacts" ||
                    // location.pathname === "/catalog/squircle" ||
                    // location.pathname === '/catalog/round'
                    //   ? "/"
                    //   : "/catalog"}
                    to="/catalog"
                    onClick={() => setIsBurgerOpen(false)}
                  >
                    {/* {location.pathname === "/catalog" ||
              location.pathname === "/contacts" ||
              location.pathname === "/catalog/squircle" ||
              location.pathname === '/catalog/round'
                ? "home"
                : "catalog"} */}
                    {t("header.catalog")}
                  </Link>
                </li>
                <li>
                  <Link
                    // to={location.pathname === "/contacts" ? "/catalog" : "/contacts"}
                    to="/contacts"
                    onClick={() => setIsBurgerOpen(false)}
                  >
                    {/* {location.pathname === "/contacts" ? "catalog" : "contacts"} */}
                    {t("header.contacts")}
                  </Link>
                </li>
                <li onClick={handleDownloadPdf} className="download">{t("header.download")}</li>
              </ul>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
