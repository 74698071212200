import React from "react";
import {
  generateSquircleItems,
  generateRoundItems,
  generateSquareItems,
} from "../../../shared/constants.js";
import { useTranslation } from "react-i18next";

const TrampolinoParams = ({ props, handleParams }) => {
  const { t, i18n } = useTranslation();
  const [squircleParams, setSquircleParams] = React.useState(
    generateSquircleItems()
  );
  const [roundParams, setRoundParams] = React.useState(generateRoundItems());
  const [squareParams, setSquareParams] = React.useState(generateSquareItems());
  const [paramItem, setParamItem] = React.useState([
    squircleParams[0].types[0],
    squircleParams[1].types[1],
  ]);
  React.useEffect(() => {
    setSquircleParams(generateSquircleItems());
    setRoundParams(generateRoundItems());
    setSquareParams(generateSquareItems());
  }, [i18n.language, props.id]);

  const handleSelection = (el, item) => {
    if (el.name === t("constructor.protection.title")) {
      setParamItem([item, paramItem[1]]);
      handleParams("Protection", item.param);
    }
    if (el.name === t("constructor.light.title")) {
      setParamItem([paramItem[0], item]);
      handleParams("Integrated lighting", item.param);
    }
  };
  return (
    <>
      {props.id === 11
        ? squircleParams.map((el) => (
            <div key={el.id} className="types--wrapper">
              <p>{el.name}</p>
              <div className="types--items">
                {el.types.map((item) => (
                  <div className="item" key={item.id}>
                    <div onClick={() => handleSelection(el, item)}>
                      {paramItem[0].param === item.param ||
                      paramItem[1].param === item.param
                        ? item.activeImage
                        : item.image}
                    </div>
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            </div>
          ))
        : props.id === 12
        ? roundParams.map((el) => (
            <div key={el.id} className="types--wrapper">
              <p>{el.name}</p>
              <div className="types--items">
                {el.types.map((item) => (
                  <div className="item" key={item.id}>
                    <div onClick={() => handleSelection(el, item)}>
                      {paramItem[0].param === item.param ||
                      paramItem[1].param === item.param
                        ? item.activeImage
                        : item.image}
                    </div>
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            </div>
          ))
        : squareParams.map((el) => (
            <div key={el.id} className="types--wrapper">
              <p>{el.name}</p>
              <div className="types--items">
                {el.types.map((item) => (
                  <div className="item" key={item.id}>
                    <div>{item.image}</div>
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
    </>
  );
};
export default TrampolinoParams;
