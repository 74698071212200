import React from "react";
import Button from "../../Button";
const FirstSection = ({ params }) => {
  const [isActive, setIsActive] = React.useState(false);
  const handleSelect = (item) => {
    if (item.state !== undefined) {
      setIsActive(!isActive);
    }
  };
  return (
    <div className="squirlceItem--container_wrapper">
      <div className="squirlce--image">
        <img
          src={isActive ? params.srcActive : params.srcBase}
          alt="trampolino light"
        />
      </div>
      <div className="squirlce--constructor">
        <h2>{params.title}</h2>
        <h3>{isActive ? params.ledModel : params.model}</h3>
        <div className="squirlce--constructor_items">
          {params.config.map((el) => (
            <div key={el.id} className="squircle_item">
              <div key={el.id} className="items--description">
                <p>{el.name}</p>
              </div>
              <div className="items--types">
                {el.types.map((item) => (
                  <div key={item.id} className="item">
                    <div
                      className={
                        item.state === isActive ? "circle" : "circle active"
                      }
                      onClick={() => handleSelect(item)}
                    ></div>
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <Button />
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
