import React from "react";
import springsPhotoRu from "../assets/images/springsRu.png";
import springsPhotoEn from "../assets/images/springsEn.png";
import springsPhotoDe from "../assets/images/springsDe.webp";
import springsPhotoTr from "../assets/images/springsTr.webp";
import springsPhotoEs from "../assets/images/springsEs.webp";
import springsPhotoFr from "../assets/images/springsFr.webp";
import defaultPhoto from "../assets/images/springsDf.webp";
import { useTranslation } from "react-i18next";
const Springs = () => {
  const { t, i18n } = useTranslation();
  var springsPhoto;
  switch (i18n.language) {
    case "ru":
      springsPhoto = springsPhotoRu;
      break;
    case "en":
      springsPhoto = springsPhotoEn;
      break;
    case "de":
      springsPhoto = springsPhotoDe;
      break;
    case "es":
      springsPhoto = springsPhotoEs;
      break;
    case "fr":
      springsPhoto = springsPhotoFr;
      break;
    case "tr":
      springsPhoto = springsPhotoTr;
      break;
    default:
      springsPhoto = defaultPhoto;
  }
  return (
    <section className="spring--container">
      <div className="spring--container__wrapper">
        <div className="spring--image">
          <img src={springsPhoto} alt="spring trampolino" />
        </div>
        <div className="description">
          <h3>{t("spring.title")}</h3>
          <p>{t("spring.subtitle")}</p>
        </div>
      </div>
    </section>
  );
};

export default Springs;
