import React from "react";
import Outdoor from "../components/Outdoor";
import Playground from "../components/Playground";
import Quality from "../components/Quality";
import Springs from "../components/Springs";
import Membranes from "../components/Membranes";
import CustomDesign from "../components/CustomDesign";
import TrampoTypes from "../components/TrampoTypes";
import LedTrampo from "../components/LedTrampo";
import Reliability from "../components/Reliability";
import Environment from "../components/Environment";
import TrampolineConstructor from "../components/Trampolines/Constructor/TrampolineConstructor";
import TableContainer from "../components/TableContainer";
import { Helmet } from "react-helmet";
const Home = () => {
  return (
    <>
      <Helmet>
        <title>Tramptec - Home</title>
      </Helmet>
      <Outdoor />
      <Playground />
      <TrampolineConstructor />
      <Quality />
      <Springs />
      <Membranes />
      <CustomDesign />
      <TrampoTypes />
      <LedTrampo />
      <Reliability />
      <Environment />
      <TableContainer />
    </>
  );
};

export default Home;
