import React from "react";
import MoundType from "../components/CatalogItems/MoundType";
import GroundType from "../components/CatalogItems/GroundType";
import Sets from "../components/CatalogItems/Sets";
import { Helmet } from "react-helmet";
const Catalog = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "auto";
  }, []);
  return (
    <>
      <Helmet>
        <title>Tramptec - Catalog</title>
      </Helmet>
      <section className="catalog--container">
        <MoundType />
        <GroundType />
        <Sets />
      </section>
    </>
  );
};

export default Catalog;
