import {nikifor_korchashkin} from '../assets/users/index'
import { nk_qr } from "../assets/users/qr-code"
export const cardData = {
   nk:{
        id: 1,
        userPhoto: nikifor_korchashkin,
        userName: {
            firstName: 'Nik',
            lastName: 'Korch'
        },
        position: 'Business Development Manager',
        phone: {
            link: 'tel:+79175113348',
            text: '+7 917 511 33 48'
        },
        email: {
            link: 'mailto:nk@tramptec.com',
            text: 'nk@tramptec.com',
        },
        telegramLink: 'https://t.me/procitio',
        whatsappLink: 'http://wa.me/79175113348',
        qr: nk_qr,
        file_name: 'nikifor_korchashkin',
        website: 'https://tramptec.com'
    },
}
