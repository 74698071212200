import React from "react";
import { Link } from "react-router-dom";
import logoTramptec from "../assets/images/logo_tramptec.png";
import tinyLogoTramptec from "../assets/icons/tinylogo.png";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
const Footer = () => {
  const year = new Date().getFullYear();
  const { t, i18n } = useTranslation();
  const [email, setEmail] = React.useState("");
  const form = React.useRef(null);
  const [isChecked, setIsChecked] = React.useState(false);
  const footerRef = React.useRef(null);
  const handleCleaner = () => {
    window.scrollTo(0, 0);
  };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_e3ez5fe",
        "template_xouk6fi",
        form.current,
        "MSKJGhKBnfjoAVFyK"
      )
      .then(
        (result) => {
          setEmail("");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <footer ref={footerRef} className="footer">
      <div className="footer--nav">
        <div className="footer--logo">
          <Link to="/" onClick={() => handleCleaner()}>
            <img src={logoTramptec} alt="tramptec logo" />
          </Link>
        </div>
        <nav>
          <ul>
            <li>
              <Link to="catalog">{t("footer.catalog")}</Link>
            </li>
            <li>
              <Link to="contacts">{t("footer.contacts")}</Link>
            </li>
            <li>
              <a href="mailto:info@citio.ru">info@tramptec.com</a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="footer--form">
        <form ref={form} onSubmit={sendEmail}>
          <p>{t("footer.feedback")}</p>
          <div className="form--inputs">
            <input
              name="user_email"
              required
              placeholder="Enter your email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="submit"
              disabled={!isChecked}
              value={t("footer.submit")}
            />
          </div>
          <div className="checkbox-container">
            <input
              checked={isChecked}
              type="checkbox"
              onChange={(e) => setIsChecked(e.target.checked)}
            />

            <p>
              {t("button.modal.accept")}
              <Link to="/politics"> {t("button.modal.link")}</Link>
            </p>
          </div>
        </form>
        <ul className="footer--links">
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.pinterest.ru/igratexru"
            >
              Pinterest
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/tramptec"
            >
              Instagram
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://t.me/igratex">
              Telegram
            </a>
          </li>
          <li>&copy; {year} Tramptec</li>
        </ul>
      </div>
      <div className="tiny--footer">
        <div className="tiny--footer_logo">
          <Link to="/" onClick={() => handleCleaner()}>
            <img src={tinyLogoTramptec} alt="tramptec tiny logo" />
          </Link>
        </div>
        <ul className="tiny--footer_links">
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.pinterest.ru/igratexru"
            >
              Pinterest
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/tramptec"
            >
              Instagram
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href="https://t.me/igratex">
              Telegram
            </a>
          </li>
        </ul>
        <div>
          <form ref={form} onSubmit={sendEmail} className="tiny--footer_form">
            <p>{t("footer.feedback")}</p>
            <div className="form--inputs">
              <input
                name="user_email"
                required
                placeholder="Enter your email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="submit"
                disabled={!isChecked}
                value={t("footer.submit")}
              />
            </div>
            <div className="checkbox-container">
              <input
                checked={isChecked}
                type="checkbox"
                onChange={(e) => setIsChecked(e.target.checked)}
              />

              <p>
                {t("button.modal.accept")}
                {i18n.language !== "ru" && (
                  <Link to="/politics"> {t("button.modal.link")}</Link>
                )}
              </p>
            </div>
          </form>
        </div>
        <div className="tiny--footer_nav">
          <nav>
            <ul>
              <li>
                <Link to="catalog">{t("footer.catalog")}</Link>
              </li>
              <li>
                <Link to="contacts">{t("footer.contacts")}</Link>
              </li>
              <li>
                <a href="mailto:info@citio.ru">info@tramptec.com</a>
              </li>
            </ul>
          </nav>
        </div>
        <p>&copy; {year} Tramptec</p>
      </div>
    </footer>
  );
};

export default Footer;
