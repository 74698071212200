import React from "react";
import FirstSection from "./FirstSection";
import Dimensions from "./Dimensions";
import { useParams } from "react-router-dom";
import { generateInGround } from "../../../shared/constants";
import { useTranslation } from "react-i18next";
import {Helmet} from 'react-helmet'
const RoundItem = () => {
  const { i18n } = useTranslation();
  const { model } = useParams();
  const [params, setParams] = React.useState(generateInGround()[model]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    setParams(generateInGround()[model]);
  }, [i18n.language]);
  return (
    <>
      <Helmet>
      <title>Tramptec - {params.title} | {params.model}</title>
      </Helmet>
      <section className="squirlceItem--container">
        <FirstSection params={params} />
        <Dimensions params={params} />
      </section>
    </>
  );
};

export default RoundItem;
