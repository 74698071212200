import React from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const Button = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const handleClose = (e) => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
    if (e.target === modal.current) {
      setIsOpen(false);
      document.body.style.overflow = "auto";
    }
  };

  const form = React.useRef(null);
  const modal = React.useRef(null);
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [name, setName] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_e3ez5fe",
        "template_xouk6fi",
        form.current,
        "MSKJGhKBnfjoAVFyK"
      )
      .then(
        (result) => {
          setEmail("");
          setMessage("");
          setName("");
          setPhone("");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <button className="button" onClick={(e) => handleClose(e)}>
        {t("button.text")}
      </button>
      {isOpen && (
        <div
          className="modal--container"
          ref={modal}
          onClick={(e) => handleClose(e)}
        >
          <div className="modal--container_wrapper">
            <p>{t("button.modal.title")}</p>
            <form ref={form} onSubmit={sendEmail}>
              <p>{t("button.modal.name")}</p>
              <input
                type="text"
                name="user_name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
              <p>{t("button.modal.email")}</p>
              <input
                required
                type="email"
                name="user_email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p>{t("button.modal.phone")}</p>
              <input
                required
                type="phone"
                name="user_phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <p>{t("button.modal.message")}</p>
              <input
                name="user_message"
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <div className="politics">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                />
                <p>{t("button.modal.accept")} <Link to='/politics'>{t('button.modal.link')}</Link></p>
              </div>
              <input
                type="submit"
                value={t("button.submit")}
                disabled={!isChecked}
              />
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Button;
