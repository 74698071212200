import React from 'react'
import {Link} from 'react-router-dom'
import notFound from '../assets/images/Tramptec_link.png'
function Not4ound() {
  return (
   <div className='notfound'>
    <div>
      <Link to ='/'><img src={notFound} alt="" /></Link>
    </div>
   </div>
  )
}

export default Not4ound