
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                header: {
                    catalog: 'Catalog',
                    contacts: 'Contacts',
                    download: 'Download'
                },
                outdors: {
                    text: 'Outdoor in-ground trampolines for all ages'
                },
                playground: {
                    title: 'Playground trampolines',
                    subtitle: 'All-weather outdoor in-ground trampolines for playground areas. Suitable for parks, schools and kindergardens. Safe and certified, vandal-proof and inclusive',
                    link: 'online catalog',
                    button: 'download pdf'
                },
                constructor: {
                    title: 'Choose your own',
                    subtitle: 'Different forms and designs for every location and playground type',
                    form: {
                        title: 'Form',
                        types: {
                            squircle: 'squircle',
                            round: 'round',
                            square: 'quadrate'
                        }
                    },
                    protection: {
                        title: 'Protection',
                        types: {
                            ring: 'ring',
                            mound: 'mound',
                            tiles: 'tiles'
                        }
                    },
                    light: {
                        title: 'Integrated lighting',
                        types: {
                            with: 'with',
                            without: 'without',
                        }
                    },
                    membranes: {
                        title: 'Jumping membranes',
                        types: {
                            fabric: 'belt fabric',
                            rubber: 'rubber sheet',
                        }
                    }
                },
                quality: {
                    title: 'Reliability and quality.',
                    subtitle: 'We have made every detail of the trampoline as reliable as possible, so that you don’t have to worry about how long it will last you',
                    activeTitle1: 'hermetic LED lighing',
                    activeSubtitle1: 'with protection against water, dust and sand',
                    activeTitle2: 'protection edge',
                    activeSubtitle2: 'ring increases the wear resistance of the trampoline',
                    activeTitle3: 'reinforced rubber membrane',
                    activeSubtitle3: 'with water drain and air circulation holes',
                    activeTitle4: 'hot-dip galvanized metal frame',
                    activeSubtitle4: 'with stiffening ribs',
                },
                spring: {
                    title: 'Reinforced springs',
                    subtitle: 'We developed special springs with an increased working length and higher web tension. The springs have a threefold margin of safety. Springs are made of galvanized steel to ensure durability and excellent corrosion resistance',
                },
                membranes: {
                    title: 'Jumping membranes',
                    rubber: {
                        title: 'Rubber sheet',
                        subtitle: 'Reinforced rubber membrane made of 6,5 mm thick Ethylene-Propylene conveyor belt. The membrane is equipped with holes for air circulation and water drain',
                        text: 'Operating temperature range -30…+50 degrees',
                        custom: 'Custom design',
                    },
                    belt: {
                        title: 'Fabric belt',
                        text: 'Operating temperature range -50…+50 degrees',
                        subtitle: 'Woven from wear-resistant belts with a breaking load of 4,500 kg. Belts are made of 4,5 mm thick UV-stabilized high-density polyester weave',
                    },
                },
                customDesign: {
                    title: 'Custom designs',
                    subtitle: 'Make a unique playground with antislip milled design patterns',
                    text: 'We can add your logo or your own jumping membrane design according to your request',
                    types: {
                        wavy: 'wavy',
                        bubbly: 'bubbly',
                        floral: 'floral',
                        custom: 'custom'
                    },
                },
                trampoTypes: {
                    title: 'Protection',
                    ring: {
                        title: 'Edge Ring',
                        text: 'Protective edge ring forms an aesthetic transition to the wet pour safety surfacing. It increases the wear resistance of the trampoline border',
                    },
                    tiles: {
                        title: 'Tiles',
                        text: 'Protection shock-absorbing tiles of 30 mm thick from pressed rubber crumb',
                    },
                    mound: {
                        title: 'Mound',
                        text: 'Protection mound from a fiberglass frame and rubber crumb of any color. Good for on-ground installation on wet pour surfacing',
                    },
                },
                ledContainer: {
                    title: 'Technical data',
                    props: {
                        prop1: 'Hermetic LED lightening',
                        prop2: 'Light temperature 3000K',
                        prop3: 'Protection degree IP67',
                        prop4: 'Optionally supplied with transformer IP67 for AC 220V',
                        prop5: '24 V',
                    },
                    text: ' Integrated',
                    subtext: 'LED light'
                },
                reliability: {
                    title: 'Reliability and quality',
                    subtitle: 'We have made every detail of the trampoline as reliable as possible, so that you don’t have to worry about how long it will last you',
                    temp: {
                        prop1: '-30°C',
                        prop2: '...+50°C',
                        descr: 'Exploitation temperature'
                    },
                    age: {
                        prop1: '3+',
                        text: 'Age',
                    },
                    load: {
                        prop1: '800 kg',
                        text: 'Maximum load',
                    },
                    guarantee: {
                        prop1: '6 years',
                        prop2: '2 years',
                        text1: 'Steel frame warranty',
                        text2: 'Components warranty',
                    },
                },
                environment: {
                    title: 'Environment',
                    subtitle: 'Materials and technical solutions retain their appearance and functionality over time and can be easily recycled',
                    tableName: 'Сharacteristic сomparison',
                    squircle: 'Squircle',
                    round: 'Round',
                    square: 'Quadrate',
                    table: {
                        row1: {
                            name: 'Size'
                        },
                        row2: {
                            name: 'Integrated lighting',
                            prop1: 'with',
                            prop2: 'without'
                        },
                        row3: {
                            name: 'Jumping membranes',
                            prop1: 'belt fabric',
                            prop2: 'rubber sheet'
                        },
                        row4: {
                            name: 'Customi- zation',
                            prop1: 'pattern',
                            prop2: 'logo'
                        },
                        row5: {
                            name: 'Protection',
                            prop1: 'ring',
                            prop2: 'mound',
                            prop3: 'tiles',
                        }
                    },
                },
                button: {
                    text: 'Get price',
                    modal: {
                        title: 'Get a price list',
                        name: 'Your name',
                        email: 'E-mail',
                        phone: 'Phone',
                        message: 'Comment',
                        accept: 'By checking this box, I accept the',
                        link: 'privacy statement'
                    },
                    submit: 'Submit',
                },
                footer: {
                    catalog: 'Catalog',
                    contacts: 'Contact us',
                    feedback: 'Get a price list',
                    submit: 'Submit',
                },
                contacts: {
                    title: "Contact us",
                    subtitle: 'Follow us',
                    text: 'Contact us if you have questions or suggestions',
                },
                catalog: {
                    in_mound: {
                        title: 'in-mound',
                        item1: 'Squircle',
                        item2: 'Round',
                    },
                    in_ground: {
                        title: 'in-ground',
                        item1: 'Squircle',
                        item2: 'Round',
                        item3: 'Quadrate',
                        item4: 'Rectangle',
                    },
                    sets: {
                        title: 'sets',
                        item1: 'Squircle',
                        item2: 'Round',
                        item3: 'Quadrate',
                    }
                },
                trompolinos: {
                    types: {
                        protection: {
                            title: 'Protection',
                            prop1: 'ring',
                            prop2: 'tiles'
                        },
                        light: {
                            title: 'Integrated lighting',
                            prop1: 'with',
                            prop2: 'without',
                        },
                        membranes: {
                            title: 'Jumping membranes',
                            prop1: 'fabric',
                            prop2: 'conveyor'
                        },
                        custom: {
                            title: 'Customize',
                            prop1: 'pattern',
                            prop2: 'logo',
                        },
                    },
                    ground: {
                        squircle: {
                            item1: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Steel frame: 1540x1540 cm',
                                    weight: 'Weight: 170 kg',
                                    height: 'Fall height: 1000x1000',
                                    area: 'Safety area: 200 cm',
                                    text: 'The device can share safety area with other equipment according to EN 1176-1:2017 / 4.2.8.2.5'
                                }
                            },
                            item2: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Steel frame: 2020x2020 cm',
                                    weight: 'Weight: 245 kg',
                                    height: 'Fall height: 1500x1500',
                                    area: 'Safety area: 200 cm',
                                    text: 'The device can share safety area with other equipment according to EN 1176-1:2017 / 4.2.8.2.5'
                                }
                            },
                        },
                        round: {
                            item2: {
                                title: 'Round',
                                details: {
                                    steel: 'Steel frame: 1600x1600 cm',
                                    weight: 'Weight: 160 kg',
                                    height: 'Fall height: ⌀1000',
                                    area: 'Safety area: 200 cm',
                                    text: 'The device can share safety area with other equipment according to EN 1176-1:2017 / 4.2.8.2.5'
                                }
                            },
                            item1: {
                                title: 'Round',
                                details: {
                                    steel: 'Steel frame: 2060x2060 cm',
                                    weight: 'Weight: 210 kg',
                                    height: 'Fall height: ⌀1480',
                                    area: 'Safety area: 200 cm',
                                    text: 'The device can share safety area with other equipment according to EN 1176-1:2017 / 4.2.8.2.5'
                                }
                            },
                        },
                        square: {
                            item2: {
                                title: 'Quadrate',
                                details: {
                                    steel: 'Steel frame: 1540x1540 cm',
                                    weight: 'Weight: 160 kg',
                                    height: 'Fall height: 150x150',
                                    area: 'Safety area: 200 cm',
                                    text: 'The device can share safety area with other equipment according to EN 1176-1:2017 / 4.2.8.2.5'
                                }
                            },
                            item1: {
                                title: 'Quadrate',
                                details: {
                                    steel: 'Steel frame: 150x150x30 cm',
                                    weight: 'Weight: 170 kg',
                                    height: 'Fall height: 1000x1000',
                                    area: 'Safety area: 200 cm',
                                    text: 'The device can share safety area with other equipment according to EN 1176-1:2017 / 4.2.8.2.5'
                                }
                            },
                        },
                        rectangle: {
                            item1: {
                                title: 'Rectangle',
                                details: {
                                    steel: 'Steel frame: 2020x2020 cm',
                                    weight: 'Weight: 225 kg',
                                    height: 'Fall height: 1500x1500',
                                    area: 'Safety area: 200 cm',
                                    text: 'The device can share safety area with other equipment according to EN 1176-1:2017 / 4.2.8.2.5'
                                }
                            }
                        }
                    },
                    mound: {
                        squircle: {
                            item1: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Hill dimensions: 2170x2170 cm',
                                    weight: 'Weight: 245 kg',
                                    height: 'Fall height: 1000x1000',
                                    area: 'Safety area: 200 cm',
                                    text: 'The device can share safety area with other equipment according to EN 1176-1:2017 / 4.2.8.2.5'
                                }
                            },
                            item2: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Hill dimensions: 2650x2650 cm',
                                    weight: 'Weight: 395 kg',
                                    height: 'Fall height: 1500x1500',
                                    area: 'Safety area: 200 cm',
                                    text: 'The device can share safety area with other equipment according to EN 1176-1:2017 / 4.2.8.2.5'
                                }
                            },
                        },
                        round: {
                            item1: {
                                title: 'Round',
                                details: {
                                    steel: 'Hill dimensions: ⌀2260 cm',
                                    weight: 'Weight: 235 kg',
                                    height: 'Fall height: ⌀1000',
                                    area: 'Safety area: 200 cm',
                                    text: 'The device can share safety area with other equipment according to EN 1176-1:2017 / 4.2.8.2.5'
                                }
                            },
                            item2: {
                                title: 'Round',
                                details: {
                                    steel: 'Hill dimensions: ⌀2650 cm',
                                    weight: 'Weight: 360 kg',
                                    height: 'Fall height: ⌀1480',
                                    area: 'Safety area: 200 cm',
                                    text: 'The device can share safety area with other equipment according to EN 1176-1:2017 / 4.2.8.2.5'
                                }
                            },
                        },

                    },
                    sets: {
                        squircle: {
                            item1: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Steel frame: 150x150x30 cm',
                                    weight: 'Weight: 160 kg',
                                    height: 'Fall height: 150x150',
                                    area: 'Safety area: 200 cm',
                                    text: 'The device can share safety area with other equipment according to EN 1176-1:2017 / 4.2.8.2.5'
                                }
                            },
                        },
                        round: {
                            item1: {
                                title: 'Round',
                                details: {
                                    steel: 'Steel frame: 150x150x30 cm',
                                    weight: 'Weight: 160 kg',
                                    height: 'Fall height: 150x150',
                                    area: 'Safety area: 200 cm',
                                    text: 'The device can share safety area with other equipment according to EN 1176-1:2017 / 4.2.8.2.5'
                                }
                            },
                        },
                        square: {
                            item1: {
                                title: 'Quadrate',
                                details: {
                                    steel: 'Steel frame: 150x150x30 cm',
                                    weight: 'Weight: 160 kg',
                                    height: 'Fall height: 150x150',
                                    area: 'Safety area: 200 cm',
                                    text: 'The device can share safety area with other equipment according to EN 1176-1:2017 / 4.2.8.2.5'
                                }
                            },
                        }
                    }
                },
                downloads: {
                    title: 'Downloads',
                    delivery: 'Delivery',
                    contacts:'Contact us',
                    certifcation: 'Certification',
                    certifcationText: 'Meet the requirements of EN 1176-1:2017 (playground equipment)',
                    dimensions: ' Dimensions',
                    product: 'Product Sheet',
                    instruction: 'Mounting instruction'
                }
            }
        },
        ru: {
            translation: {
                header: {
                    catalog: 'Каталог',
                    contacts: 'Контакты',
                    download: 'Загрузить'
                },
                outdors: {
                    text: 'Уличные встраиваемые батуты для детей всех возрастов'
                },
                playground: {
                    title: 'Уличные батуты',
                    subtitle: 'Всепогодные уличные батуты для детских площадок. Подходят для парков, школ, детских садов и общественных пространств. Безопасные, сертифицированные, антивандальные',
                    link: 'онлайн каталог',
                    button: 'скачать pdf'
                },
                constructor: {
                    title: 'Выбери свой',
                    subtitle: 'Различные формы и решения для каждого типа игровой площадки',
                    form: {
                        title: 'Форма',
                        types: {
                            squircle: 'сквиркл',
                            round: 'круг',
                            square: 'квадрат'
                        }
                    },
                    protection: {
                        title: 'Защита',
                        types: {
                            ring: 'борт',
                            tiles: 'подушки',
                            mound: 'холм'
                        }
                    },
                    light: {
                        title: 'Встроенная подсветка',
                        types: {
                            with: 'есть',
                            without: 'нет',
                        }
                    },
                    membranes: {
                        title: 'Прыжковое полотно',
                        types: {
                            fabric: 'тканевое полотно',
                            rubber: 'резиновое полотно',
                        }
                    }

                },
                quality: {
                    title: 'Надежность и качество',
                    subtitle: 'Мы сделали каждую деталь батута максимально надежной, чтобы вам не пришлось беспокоиться о том, как долго он вам прослужит',
                    activeTitle1: 'герметичное LED освещение',
                    activeSubtitle1: 'с защитой от воды, пыли и песка',
                    activeTitle2: 'защитная кромка',
                    activeSubtitle2: 'кольцо повышает износостойкость батута',
                    activeTitle3: 'усиленная резиновая мембрана',
                    activeSubtitle3: 'с отверстиями для слива воды и циркуляции воздуха',
                    activeTitle4: 'металлическая рама из горячего цинкования',
                    activeSubtitle4: 'с ребрами жесткости',
                },
                spring: {
                    title: 'Усиленные пружины',
                    subtitle: 'Мы разработали специальные пружины с увеличенной рабочей длинной и усилили их исходное натяжение полотна. Пружины имеют трехкратный запас прочности. Пружины изготовлены из оцинкованной стали, что обеспечивает долговечность и отличную коррозионную стойкость',
                },
                membranes: {
                    title: 'Прыжковое полотно',
                    rubber: {
                        title: 'Резиновое полотно',
                        subtitle: 'Сплошная мембрана из резины, армированной двумя слоями полиэфирной ткани. Толщина полотна 6,5 мм.Полотно перфорировано отверстиями для схода осадков и снижения эффекта воздушного демпфирования при эксплуатации',
                        text: 'Диапазон температур эксплуатации -30..+50 градусов',
                        custom: 'Кастомный дизайн',
                    },
                    belt: {
                        title: 'Тканевое полотно',
                        subtitle: 'Сшитое и сплетенное из износостойких ремней с разрывной нагрузкой 4 500 кг. Толщина полотна 4,5 мм. Материал: УФ-стабилизированный полиэфир высокой плотности плетения',
                        text: 'Диапазон температур эксплуатации -50…+50 градусов',
                    },
                },
                customDesign: {
                    title: 'Кастомный дизайн',
                    subtitle: 'Создайте уникальную игровую площадку с помощью противоскользящих фрезерованных узоров',
                    text: 'По вашему запросу мы можем добавить ваш логотип или собственный дизайн прыжковой мембраны',
                    types: {
                        wavy: 'волны',
                        bubbly: 'тетрис',
                        floral: 'флора',
                        custom: 'ваш дизайн'
                    },
                },
                trampoTypes: {
                    title: 'Защита',
                    ring: {
                        title: 'Борт',
                        text: 'Резиновый борт повышает износостойкость границы батута, формируя эстетичный переход к бесшовному покрытию',
                    },
                    tiles: {
                        title: 'Подушки',
                        text: 'Ударопоглащающие подушки толщиной 30 мм из прессованной резиновой крошки',
                    },
                    mound: {
                        title: 'Холм',
                        text: 'Защитный холм из стекловолокна и резиновой крошки любого цвета. Подходит для установки в сыпучее покрытие',
                    },
                },
                ledContainer: {
                    title: 'Технические характеристики',
                    props: {
                        prop1: 'Герметичная LED-подсветка',
                        prop2: 'Температура света 3000К',
                        prop3: 'Степень защиты IP67.',
                        prop4: 'Опционально поставляется с трансформатором IP67 для сети AC 220V',
                        prop5: '24 V',
                    },
                    text: 'Встроенная',
                    subtext: 'LED подсветка'
                },
                reliability: {
                    title: 'Надежность и качество',
                    subtitle: 'Мы сделали каждую деталь батута максимально надежной, чтобы вам не пришлось беспокоиться о том, как долго он вам прослужит',
                    temp: {
                        prop1: '-30°C',
                        prop2: '...+50°C',
                        descr: 'Температура эксплуатации'
                    },
                    age: {
                        prop1: '3+',
                        text: 'Возраст',
                    },
                    load: {
                        prop1: '800кг',
                        text: 'Максимальная нагрузка',
                    },
                    guarantee: {
                        prop1: '6 лет',
                        prop2: '2 года',
                        text1: 'Гарантия на стальную раму',
                        text2: 'Гарантия на комплектующие',
                    },
                },
                environment: {
                    title: 'Окружающая среда',
                    subtitle: 'Материалы и технические решения сохраняют свой внешний вид и функциональность с течением времени и легко поддаются вторичной переработке',
                    tableName: 'Сравнительные характеристики',
                    squircle: 'Сквиркл',
                    round: 'Круг',
                    square: 'Квадрат',
                    table: {
                        row1: {
                            name: 'Габариты'
                        },
                        row2: {
                            name: 'Встроенная подсветка',
                            prop1: 'есть',
                            prop2: 'нет'
                        },
                        row3: {
                            name: 'Прыжковое полотно',
                            prop1: 'тканевое полотно',
                            prop2: 'резиновое полотно'
                        },
                        row4: {
                            name: 'Кастоми-зация',
                            prop1: 'шаблон',
                            prop2: 'лого'
                        },
                        row5: {
                            name: 'Защита',
                            prop1: 'борт',
                            prop2: 'холм',
                            prop3: 'подушки',
                        }
                    },
                },
                button: {
                    text: 'Получить прайс',
                    modal: {
                        title: 'Получить цены',
                        name: 'Ваше имя',
                        email: 'Email',
                        phone: 'Телефон',
                        message: 'Комментарий',
                        accept: 'Ставя отметку, я подтверждаю согласие на обработку моих персональных данных (закон №152-ФЗ «О персональных данных»)',
                        link: '',
                    },
                    submit: 'Подтвердить',
                },
                footer: {
                    catalog: 'Каталог',
                    contacts: 'Контакты',
                    feedback: 'Получить прaйс-лист',
                    submit: 'Подтвердить',
                },
                contacts: {
                    title: "Связаться с нами",
                    subtitle: 'Присоединяйся к нам',
                    text: 'Свяжитесь с нами если у вас есть вопросы и пожелания',
                },
                catalog: {
                    in_mound: {
                        title: 'в холме',
                        item1: 'Сквиркл',
                        item2: 'Круг',
                    },
                    in_ground: {
                        title: 'встроенный',
                        item1: 'Сквиркл',
                        item2: 'Круг',
                        item3: 'Квадрат',
                        item4: 'Прямоугольник',
                    },
                    sets: {
                        title: 'сеты',
                        item1: 'Сквиркл',
                        item2: 'Круг',
                        item3: 'Квадрат',
                    }
                },
                trompolinos: {
                    types: {
                        protection: {
                            title: 'Защита',
                            prop1: 'борт',
                            prop2: 'подушки'
                        },
                        light: {
                            title: 'Встроенная подсветка',
                            prop1: 'есть',
                            prop2: 'нет',
                        },
                        membranes: {
                            title: 'Прыжковое полотно',
                            prop1: 'ткань',
                            prop2: 'резина'
                        },
                        custom: {
                            title: 'Кастомизация',
                            prop1: 'шаблон',
                            prop2: 'логотип',
                        },
                    },
                    ground: {
                        squircle: {
                            item1: {
                                title: 'Сквиркл',
                                details: {
                                    steel: 'Стальна рама: 1540x1540 cm',
                                    weight: 'Вес: 170 kg',
                                    height: 'Габариты полотна: 1000x1000',
                                    area: 'Защитная зона: 200 cm',
                                    text: 'Устройство может разделять зону безопасности с другим оборудованием согласно EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                            item2: {
                                title: 'Сквиркл',
                                details: {
                                    steel: 'Стальна рама: 2020x2020 cm',
                                    weight: 'Вес: 245 kg',
                                    height: 'Габариты полотна: 1500x1500',
                                    area: 'Защитная зона: 200 cm',
                                    text: 'Устройство может разделять зону безопасности с другим оборудованием согласно EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        },
                        round: {
                            item2: {
                                title: 'Круглый',
                                details: {
                                    steel: 'Стальна рама: 1600x1600 cm',
                                    weight: 'Вес: 160 kg',
                                    height: 'Габариты полотна: ⌀1000',
                                    area: 'Защитная зона: 200 cm',
                                    text: 'Устройство может разделять зону безопасности с другим оборудованием согласно EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                            item1: {
                                title: 'Круглый',
                                details: {
                                    steel: 'Стальна рама: 2060x2060 cm',
                                    weight: 'Вес: 210 kg',
                                    height: 'Габариты полотна: ⌀1480',
                                    area: 'Защитная зона: 200 cm',
                                    text: 'Устройство может разделять зону безопасности с другим оборудованием согласно EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        },
                        square: {
                            item2: {
                                title: 'Квадратный',
                                details: {
                                    steel: 'Стальна рама: 1540x1540 cm',
                                    weight: 'Вес: 160 kg',
                                    height: 'Габариты полотна: 150x150',
                                    area: 'Защитная зона: 200 cm',
                                    text: 'Устройство может разделять зону безопасности с другим оборудованием согласно EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                            item1: {
                                title: 'Квадратный',
                                details: {
                                    steel: 'Стальна рама: 150x150x30 cm',
                                    weight: 'Вес: 170 kg',
                                    height: 'Габариты полотна: 1000x1000',
                                    area: 'Защитная зона: 200 cm',
                                    text: 'Устройство может разделять зону безопасности с другим оборудованием согласно EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        },
                        rectangle: {
                            item1: {
                                title: 'Прямоугольный',
                                details: {
                                    steel: 'Стальна рама: 2020x2020 cm',
                                    weight: 'Вес: 225 kg',
                                    height: 'Габариты полотна: 1500x1500',
                                    area: 'Защитная зона: 200 cm',
                                    text: 'Устройство может разделять зону безопасности с другим оборудованием согласно EN 1176-1:2017/4.2.8.2.5.'
                                }
                            }
                        }
                    },
                    mound: {
                        squircle: {
                            item1: {
                                title: 'Сквиркл',
                                details: {
                                    steel: 'Габарит холма: 2170х2170 cm',
                                    weight: 'Вес: 245 kg',
                                    height: 'Габариты полотна: 1000х1000',
                                    area: 'Защитная зона: 200 cm',
                                    text: 'Устройство может разделять зону безопасности с другим оборудованием согласно EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                            item2: {
                                title: 'Сквиркл',
                                details: {
                                    steel: 'Габарит холма: 2650х2650 cm',
                                    weight: 'Вес: 395 kg',
                                    height: 'Габариты полотна: 1500x1500',
                                    area: 'Защитная зона: 200 cm',
                                    text: 'Устройство может разделять зону безопасности с другим оборудованием согласно EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        },
                        round: {
                            item1: {
                                title: 'Круглый',
                                details: {
                                    steel: 'Габарит холма: ⌀2260 cm',
                                    weight: 'Вес: 235 kg',
                                    height: 'Габариты полотна: ⌀1000',
                                    area: 'Защитная зона: 200 cm',
                                    text: 'Устройство может разделять зону безопасности с другим оборудованием согласно EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                            item2: {
                                title: 'Круглый',
                                details: {
                                    steel: 'Габарит холма: ⌀2650 cm',
                                    weight: 'Вес: 360 kg',
                                    height: 'Габариты полотна: ⌀1480',
                                    area: 'Защитная зона: 200 cm',
                                    text: 'Устройство может разделять зону безопасности с другим оборудованием согласно EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        },

                    },
                    sets: {
                        squircle: {
                            item1: {
                                title: 'Сквикрл',
                                details: {
                                    steel: 'Стальна рама: 150x150x30 cm',
                                    weight: 'Вес: 160 kg',
                                    height: 'Габариты полотна: 150x150',
                                    area: 'Защитная зона: 200 cm',
                                    text: 'Устройство может разделять зону безопасности с другим оборудованием согласно EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        },
                        round: {
                            item1: {
                                title: 'Круглые',
                                details: {
                                    steel: 'Стальна рама: 150x150x30 cm',
                                    weight: 'Вес: 160 kg',
                                    height: 'Габариты полотна: 150x150',
                                    area: 'Защитная зона: 200 cm',
                                    text: 'Устройство может разделять зону безопасности с другим оборудованием согласно EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        },
                        square: {
                            item1: {
                                title: 'Квадратыне',
                                details: {
                                    steel: 'Стальна рама: 150x150x30 cm',
                                    weight: 'Вес: 160 kg',
                                    height: 'Габариты полотна: 150x150',
                                    area: 'Защитная зона: 200 cm',
                                    text: 'Устройство может разделять зону безопасности с другим оборудованием согласно EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        }
                    }
                },
                downloads: {
                    title: 'Загрузки',
                    delivery: 'Доставка',
                    certifcation: 'Сертификация',
                    contacts:'Напиши нам',
                    certifcationText: 'Соответствует требованиям ТР ЕАЭС 042/2017 и ГОСТ 34614.1-2019',
                    dimensions: ' Размеры',
                    product: 'Лист продукта',
                    instruction: 'Инструкция по монтажу'
                }
            },
        },
        tr: {
            translation: {
                header: {
                    catalog: 'Katalog',
                    contacts: 'İletişim',
                    download: 'İndir'
                },
                outdors: {
                    text: 'Her yaştan çocuklar için dış mekanda yerleşik trambolinler'
                },
                playground: {
                    title: 'Açık hava trambolinleri',
                    subtitle: 'Oyun alanları için her türlü hava koşularına uygun dış mekan trambolinleri. Parklar, okullar, anaokulları ve kamusal alanlar için uygundur. Güvenli, sertifikalı, vandalizme dayanıklıdır',
                    link: 'Onlayn katalog',
                    button: 'pdf indir'
                },
                constructor: {
                    title: 'Kendine seç',
                    subtitle: 'Her oyun alanı için farklı şekiller ve çözümler',
                    form: {
                        title: 'Şekil',
                        types: {
                            squircle: 'Squircle',
                            round: 'daire',
                            square: 'kare'
                        }
                    },
                    protection: {
                        title: 'Koruma',
                        types: {
                            ring: 'pano',
                            tiles: 'yastıklar',
                            mound: 'tepe'
                        }
                    },
                    light: {
                        title: 'Araka ışık dahil',
                        types: {
                            with: 'var',
                            without: 'yok',
                        }
                    },
                    membranes: {
                        title: 'Atlama yüzeyi',
                        types: {
                            fabric: 'kumaş yüzeyi',
                            rubber: 'lastik yüzeyi',
                        }
                    }

                },
                quality: {
                    title: 'Güvenilirlik ve kalite',
                    subtitle: 'Trambolinin her parçasını mümkün olduğunca güvenilir hale getirdik, böylece ne kadar süre dayanacağı konusunda endişelenmenize gerek yok',
                    activeTitle1: 'germetik LED ışık sistemi',
                    activeSubtitle1: 'sudan, tozdan ve kumdan koruyacaktır',
                    activeTitle2: 'koruyucu kenar ',
                    activeSubtitle2: 'halkası trambolinin aşınma direncini artırır',
                    activeTitle3: 'Su drenajı ve hava sirkülasyonu',
                    activeSubtitle3: 'için delikli güçlendirilmiş lastik membranı ',
                    activeTitle4: 'metal çerçevesi sıcak çınkodan',
                    activeSubtitle4: 'sertleştirici kenarıyla',
                },
                spring: {
                    title: 'Güçlendirilmiş yaylar',
                    subtitle: 'Çalışma uzunluğu artırılmış ve orijinal ağ gerilimini arttırılmış özel yaylar geliştirdik. Yayların üç kat güvenlik marjı vardır. Yaylar, dayanıklılık ve mükemmel korozyon direnci için galvanizli çelikten yapılmıştır',
                },
                membranes: {
                    title: 'Atlama yüzeyi',
                    rubber: {
                        title: 'Lastik yüzeyi',
                        subtitle: 'İki kat polyester kumaşla güçlendirilmiş kauçuktan yapılmış sürekli bir membran. Kanvasın kalınlığı 6,5 mm\'dir Kanvas, çökeltinin tahliye edilmesini sağlamak ve çalışma sırasında hava sönümleme etkisini azaltmak için deliklerle delinmiştir.',
                        text: 'Çalışma sıcaklığı aralığı -30..+50 derece',
                        custom: 'Özel tasarım',
                    },
                    belt: {
                        title: 'Kumaş yüzeyi',
                        subtitle: '4.500 kg kopma yüküne sahip, aşınmaya dayanıklı kayışlardan dikilir ve dokunur. Kanvasın kalınlığı 4,5 mm\'dir. Malzeme: UV stabilizeli yüksek yoğunluklu polyester dokuma',
                        text: 'Çalışma sıcaklığı aralığı -50…+50 derece',
                    },
                },
                customDesign: {
                    title: 'Özel tasarım',
                    subtitle: 'Kaymayı önleyici frezelenmiş desenlerle benzersiz bir oyun alanı yaratın',
                    text: 'İsteğinize göre logonuzu veya kendi atlama membranı tasarımımızı ekleyebiliriz',
                    types: {
                        wavy: 'dalgalar',
                        bubbly: 'tetris',
                        floral: 'bitki örtüsü',
                        custom: 'senin tasarımın'
                    },
                },
                trampoTypes: {
                    title: 'Koruma',
                    ring: {
                        title: 'Pano',
                        text: 'Kauçuk kenar, trambolin bordürünün aşınma direncini artırarak kesintisiz bir yüzeye estetik bir geçiş yaratır',
                    },
                    tiles: {
                        title: 'Yastıklar',
                        text: 'Sıkıştırılmış kauçuk kırıntılarından yapılmış 30 mm kalınlığında şok emici yastıklar',
                    },
                    mound: {
                        title: 'Tepe',
                        text: 'Cam elyafından ve her renkteki kırıntı kauçuktan yapılmış koruyucu tepe. Gevşek kaplamaya montaja uygun',
                    },
                },
                ledContainer: {
                    title: 'Teknik özellikler',
                    props: {
                        prop1: 'Kapalı LED arka ışık',
                        prop2: 'Işık sıcaklığı 3000K',
                        prop3: 'Koruma derecesi IP67.',
                        prop4: 'AC 220V ağı için isteğe bağlı olarak IP67 transformatörle birlikte verilir',
                        prop5: '24 V',
                    },
                    text: 'Dahili',
                    subtext: 'LED aydınlatma'
                },
                reliability: {
                    title: 'Güvenilirlik ve kalite',
                    subtitle: 'Trambolinin her parçasını mümkün olduğunca güvenilir hale getirdik, böylece ne kadar süre dayanacağı konusunda endişelenmenize gerek yok',
                    temp: {
                        prop1: '-30°C',
                        prop2: '...+50°C',
                        descr: 'Çalışma sıcaklığı',
                    },
                    age: {
                        prop1: '3+',
                        text: 'Yaş',
                    },
                    load: {
                        prop1: '800kg',
                        text: 'Maksimum yük',
                    },
                    guarantee: {
                        prop1: '6 yıl',
                        prop2: '2 yıl',
                        text1: 'Çelik çerçeve garantisi',
                        text2: 'Bileşenler için garanti',
                    },
                },
                environment: {
                    title: 'Çevre',
                    subtitle: 'Malzemeler ve teknik çözümler zamanla görünümlerini ve işlevlerini korur ve kolayca geri dönüştürülebilir',
                    tableName: 'Karşılaştırmalı özellikler',
                    squircle: 'Squircle',
                    round: 'Daire',
                    square: 'Kare',
                    table: {
                        row1: {
                            name: 'Boyutlar'
                        },
                        row2: {
                            name: 'Dahili arka ışık',
                            prop1: 'var',
                            prop2: 'yok'
                        },
                        row3: {
                            name: 'Atlama yüzeyi',
                            prop1: 'kumaş yüzeyi',
                            prop2: 'lastik  yüzeyi'
                        },
                        row4: {
                            name: 'Özelle-ştirme',
                            prop1: 'Örnek',
                            prop2: 'Logo'
                        },
                        row5: {
                            name: 'Koruma',
                            prop1: 'Pano',
                            prop2: 'Tepe',
                            prop3: 'Yastıklar',
                        }
                    },
                },
                button: {
                    text: 'Fiyatı al',
                    modal: {
                        title: 'Fiyat almak',
                        name: 'Adınız',
                        email: 'Email',
                        phone: 'Telefon',
                        message: 'Bir yorum',
                        accept: 'By checking this box, I accept the',
                        link: 'privacy statement'
                    },
                    submit: 'Onayla',
                },
                footer: {
                    catalog: 'Katalog',
                    contacts: 'İletişim',
                    feedback: 'Fiyat listesini al',
                    submit: 'Onayla',
                },
                contacts: {
                    title: "Bizimle iletişime geçin",
                    subtitle: 'Bize katılın',
                    text: 'Sorularınız veya önerileriniz varsa bizimle iletişime geçin',
                },
                catalog: {
                    in_mound: {
                        title: 'Tepede',
                        item1: 'Squircle',
                        item2: 'Daire',
                    },
                    in_ground: {
                        title: 'Yerleşik',
                        item1: 'Squircle',
                        item2: 'Daire',
                        item3: 'Kare',
                        item4: 'Dikdörtgen',
                    },
                    sets: {
                        title: 'Setler',
                        item1: 'Squircle',
                        item2: 'Daire',
                        item3: 'Kare',
                    }
                },
                trompolinos: {
                    types: {
                        protection: {
                            title: 'Koruma',
                            prop1: 'Pano',
                            prop2: 'yastıklar'
                        },
                        light: {
                            title: 'Araka ışık dahil',
                            prop1: 'Var',
                            prop2: 'Yok',
                        },
                        membranes: {
                            title: 'Atlama yüzeyi',
                            prop1: 'tekstil',
                            prop2: 'lastik'
                        },
                        custom: {
                            title: 'Özelleştirme',
                            prop1: 'Örnek',
                            prop2: 'Logo',
                        },
                    },
                    ground: {
                        squircle: {
                            item1: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Çelik çerçeve: 1540x1540 cm',
                                    weight: 'Ağırlık: 170 kg',
                                    height: 'Kanvas boyutları: 1000x1000',
                                    area: 'Koruma bölgesi: 200 cm',
                                    text: 'Cihaz, EN 1176-1:2017/4.2.8.2.5\'e göre güvenlik bölgesini diğer ekipmanlarla ayırabilir. '
                                }
                            },
                            item2: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Çelik çerçeve: 2020x2020 cm',
                                    weight: 'Ağırlık: 245 kg',
                                    height: 'Kanvas boyutları: 1500x1500',
                                    area: 'Koruma bölgesi: 200 cm',
                                    text: 'Cihaz, EN 1176-1:2017/4.2.8.2.5\'e göre güvenlik bölgesini diğer ekipmanlarla ayırabilir. '
                                }
                            },
                        },
                        round: {
                            item2: {
                                title: 'Yuvarlak',
                                details: {
                                    steel: 'Çelik çerçeve: 1600x1600 cm',
                                    weight: 'Ağırlık: 160 kg',
                                    height: 'Kanvas boyutları: ⌀1000',
                                    area: 'Koruma bölgesi: 200 cm',
                                    text: 'Cihaz, EN 1176-1:2017/4.2.8.2.5\'e göre güvenlik bölgesini diğer ekipmanlarla ayırabilir. '
                                }
                            },
                            item1: {
                                title: 'Yuvarlak',
                                details: {
                                    steel: 'Çelik çerçeve: 2060x2060 cm',
                                    weight: 'Ağırlık: 210 kg',
                                    height: 'Kanvas boyutları: ⌀1480',
                                    area: 'Koruma bölgesi: 200 cm',
                                    text: 'Cihaz, EN 1176-1:2017/4.2.8.2.5\'e göre güvenlik bölgesini diğer ekipmanlarla ayırabilir. '
                                }
                            },
                        },
                        square: {
                            item2: {
                                title: 'Kare',
                                details: {
                                    steel: 'Çelik çerçeve: 1540x1540 cm',
                                    weight: 'Ağırlık: 160 kg',
                                    height: 'Kanvas boyutları: 150x150',
                                    area: 'Koruma bölgesi: 200 cm',
                                    text: 'Cihaz, EN 1176-1:2017/4.2.8.2.5\'e göre güvenlik bölgesini diğer ekipmanlarla ayırabilir. '
                                }
                            },
                            item1: {
                                title: 'Kare',
                                details: {
                                    steel: 'Çelik çerçeve: 150x150x30 cm',
                                    weight: 'Ağırlık: 170 kg',
                                    height: 'Kanvas boyutları: 1000x1000',
                                    area: 'Koruma bölgesi: 200 cm',
                                    text: 'Cihaz, EN 1176-1:2017/4.2.8.2.5\'e göre güvenlik bölgesini diğer ekipmanlarla ayırabilir. '
                                }
                            },
                        },
                        rectangle: {
                            item1: {
                                title: 'Dikdörtgen',
                                details: {
                                    steel: 'Çelik çerçeve: 2020x2020 cm',
                                    weight: 'Ağırlık: 225 kg',
                                    height: 'Kanvas boyutları: 1500x1500',
                                    area: 'Koruma bölgesi: 200 cm',
                                    text: 'Cihaz, EN 1176-1:2017/4.2.8.2.5\'e göre güvenlik bölgesini diğer ekipmanlarla ayırabilir. '
                                }
                            }
                        }
                    },
                    mound: {
                        squircle: {
                            item1: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Tepe boyutu: 2170х2170 cm',
                                    weight: 'Ağırlık: 245 kg',
                                    height: 'Kanvas boyutları: 1000х1000',
                                    area: 'Koruma bölgesi: 200 cm',
                                    text: 'Cihaz, EN 1176-1:2017/4.2.8.2.5\'e göre güvenlik bölgesini diğer ekipmanlarla ayırabilir. '
                                }
                            },
                            item2: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Tepe boyutu: 2650х2650 cm',
                                    weight: 'Ağırlık: 395 kg',
                                    height: 'Kanvas boyutları: 1500x1500',
                                    area: 'Koruma bölgesi: 200 cm',
                                    text: 'Cihaz, EN 1176-1:2017/4.2.8.2.5\'e göre güvenlik bölgesini diğer ekipmanlarla ayırabilir. '
                                }
                            },
                        },
                        round: {
                            item1: {
                                title: 'Yuvarlak',
                                details: {
                                    steel: 'Tepe boyutu: ⌀2260 cm',
                                    weight: 'Ağırlık: 235 kg',
                                    height: 'Kanvas boyutları: ⌀1000',
                                    area: 'Koruma bölgesi: 200 cm',
                                    text: 'Cihaz, EN 1176-1:2017/4.2.8.2.5\'e göre güvenlik bölgesini diğer ekipmanlarla ayırabilir. '
                                }
                            },
                            item2: {
                                title: 'Yuvarlak',
                                details: {
                                    steel: 'Tepe boyutu: ⌀2650 cm',
                                    weight: 'Ağırlık: 360 kg',
                                    height: 'Kanvas boyutları: ⌀1480',
                                    area: 'Koruma bölgesi: 200 cm',
                                    text: 'Cihaz, EN 1176-1:2017/4.2.8.2.5\'e göre güvenlik bölgesini diğer ekipmanlarla ayırabilir. '
                                }
                            },
                        },

                    },
                    sets: {
                        squircle: {
                            item1: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Çelik çerçeve: 150x150x30 cm',
                                    weight: 'Ağırlık: 160 kg',
                                    height: 'Kanvas boyutları: 150x150',
                                    area: 'Koruma bölgesi: 200 cm',
                                    text: 'Cihaz, EN 1176-1:2017/4.2.8.2.5\'e göre güvenlik bölgesini diğer ekipmanlarla ayırabilir. '
                                }
                            },
                        },
                        round: {
                            item1: {
                                title: 'Yuvarlak',
                                details: {
                                    steel: 'Çelik çerçeve: 150x150x30 cm',
                                    weight: 'Ağırlık: 160 kg',
                                    height: 'Kanvas boyutları: 150x150',
                                    area: 'Koruma bölgesi: 200 cm',
                                    text: 'Cihaz, EN 1176-1:2017/4.2.8.2.5\'e göre güvenlik bölgesini diğer ekipmanlarla ayırabilir. '
                                }
                            },
                        },
                        square: {
                            item1: {
                                title: 'Kare',
                                details: {
                                    steel: 'Çelik çerçeve: 150x150x30 cm',
                                    weight: 'Ağırlık: 160 kg',
                                    height: 'Kanvas boyutları: 150x150',
                                    area: 'Koruma bölgesi: 200 cm',
                                    text: 'Cihaz, EN 1176-1:2017/4.2.8.2.5\'e göre güvenlik bölgesini diğer ekipmanlarla ayırabilir. '
                                }
                            },
                        }
                    }
                },
                downloads: {
                    title: 'İndirilenler',
                    delivery: 'Teslimat',
                    certifcation: 'Sertifikasyon',
                    contacts:'Bizimle iletişime geçin',
                    certifcationText: 'TR EAEU 042/2017 ve GOST 34614.1-2019 gerekliliklerine uygundur ',
                    dimensions: 'Boyutlar',
                    product: 'Ürün sayfası',
                    instruction: 'Kurulum Talimatları'
                }
            },
        },
        de: {
            translation: {
                header: {
                    catalog: 'Katalog',
                    contacts: 'Kontakte',
                    download: 'Download'
                },
                outdors: {
                    text: 'Eingebaute Outdoor-Trampoline für Kinder jeden Alters'
                },
                playground: {
                    title: 'Outdoor-Trampoline',
                    subtitle: 'Allwetter-Outdoor-Trampoline für Kinderspielplätze. Geeignet für Parks, Schulen, Kindergärten und öffentliche Räume. Sicher, zertifiziert, vandalismussicher',
                    link: 'online-Katalog',
                    button: 'PDF herunterladen',
                },
                constructor: {
                    // title: 'Wählen Sie ihres aus',
                    title: '',
                    subtitle: 'Verschiedene Formen und Lösungen für jeden Spielplatz',
                    form: {
                        title: 'Form',
                        types: {
                            squircle: 'Squircle',
                            round: 'Kreis',
                            square: 'Quadrat'
                        }
                    },
                    protection: {
                        title: 'Schutz',
                        types: {
                            ring: 'Rund',
                            tiles: 'Polster',
                            mound: 'Hügel'
                        }
                    },
                    light: {
                        title: 'Integrierte Beleuchtung',
                        types: {
                            with: 'Ja',
                            without: 'Nein',
                        }
                    },
                    membranes: {
                        title: 'Sprungtuch',
                        types: {
                            fabric: 'Stoffsprungtuch',
                            rubber: 'Gummisprungtuch',
                        }
                    }

                },
                quality: {
                    title: 'Zuverlässigkeit und Qualität. ',
                    subtitle: 'Wir haben jedes Detail des Trampolins so hochwertig wie möglich gestaltet, damit Sie sich keine Sorgen um die Lebensdauer machen müssen',
                    activeTitle1: 'LED-Beleuchtung',
                    activeSubtitle1: 'mit Schutz vor Wasser, Staub und Sand',
                    activeTitle2: 'Schutzkante',
                    activeSubtitle2: 'zur Erhöhung der Abriebfestigkeit des Trampolins',
                    activeTitle3: 'Verstärkte Gummimembran',
                    activeSubtitle3: 'mit Löchern für Wasserablauf und Luftzirkulation',
                    activeTitle4: 'feuerverzinkter Metallrahmen',
                    activeSubtitle4: 'mit Versteifungsrippen',
                },
                spring: {
                    title: 'Verstärkte Federn',
                    subtitle: 'Wir haben spezielle Federn entwickelt, die eine erhöhte Arbeitslänge aufweisen und die ursprüngliche Bahnspannung verstärken. Die Federn verfügen über eine  dreifache Festigkeit, da sie aus verzinktem Stahl gefertigt wurden, was Langlebigkeit und hervorragende Korrosionsbeständigkeit garantiert',
                },
                membranes: {
                    title: 'Sprungtuch',
                    rubber: {
                        title: 'Gummisprungtuch',
                        subtitle: 'Solide Gummimembran, verstärkt durch zwei Schichten Polyester-Gewebe. Die Dicke des Tuches beträgt 6,5 mm. Das Tuch ist perforiert mit Löchern für den Abfluss von Niederschlägen und zur Reduzierung des Luftdämpfungseffekts während der Nutzung',
                        text: 'Temperaturbereich -30..+50 Grad',
                        custom: 'Individuelles Design',
                    },
                    belt: {
                        title: 'Stoffsprungtuch',
                        subtitle: 'Genäht und geflochten aus reißfesten Gurten mit einer Bruchlast von 4.500 kg. Die Dicke des Tuches beträgt 4,5 mm. Material: UV-stabilisierter, hochdichter Polyester',
                        text: 'Temperaturbereich -50…+50 Grad',
                    },
                },
                customDesign: {
                    title: 'Individuelles Design',
                    subtitle: 'Erstellen Sie einen einzigartigen Spielplatz mit rutschfesten Fräsmustern',
                    text: 'Auf Anfrage können wir Ihr Logo oder Ihr eigenes Design auf der Sprungmembran hinzufügen',
                    types: {
                        wavy: 'Wellen',
                        bubbly: 'Tetris',
                        floral: 'Flora',
                        custom: 'Ihr Design'
                    },
                },
                trampoTypes: {
                    title: 'Schutz',
                    ring: {
                        title: 'Kante',
                        text: 'Der Gummischutzrand verbessert die Abriebfestigkeit der Grenze des Trampolins und schafft einen ästhetischen Übergang zum nahtlosen Belag',
                    },
                    tiles: {
                        title: 'Polster',
                        text: '30 mm dicke stoßdämpfende Polster aus gepresstem Gummigranulat',
                    },
                    mound: {
                        title: 'Hügel',
                        text: 'Schutzhügel aus Glasfaser und Gummigranulat in jeder Farbe. Geeignet für die Installation in loser Schüttung',
                    },
                },
                ledContainer: {
                    title: 'Technische Eigenschaften',
                    props: {
                        prop1: 'Wasserdichte LED-Beleuchtung',
                        prop2: 'Farbtemperatur 3000K',
                        prop3: 'Schutzart IP67.',
                        prop4: 'Optional mit IP67-Transformator für das AC 220V-Netzwerk geliefert',
                        prop5: '24 V',
                    },
                    text: 'Integrierte',
                    subtext: 'LED-Beleuchtung'
                },
                reliability: {
                    title: 'Zuverlässigkeit und Qualität. ',
                    subtitle: 'Wir haben jedes Detail des Trampolins so zuverlässig wie möglich gemacht, damit Sie sich keine Sorgen um seine Lebensdauer machen müssen',
                    temp: {
                        prop1: '-30°C',
                        prop2: '...+50°C',
                        descr: 'Betriebstemperatur'
                    },
                    age: {
                        prop1: '3+',
                        text: 'Alter',
                    },
                    load: {
                        prop1: '800kg',
                        text: 'Maximale Belastung',
                    },
                    guarantee: {
                        prop1: '6 Jahre',
                        prop2: '2 Jahre',
                        text1: 'Garantie auf den Stahlrahmen',
                        text2: 'Garantie auf Teile',
                    },
                },
                environment: {
                    title: 'Umwelt',
                    subtitle: 'Die Materialien und technischen Lösungen behalten im Laufe der Zeit ihr Aussehen und ihre Funktionalität und sind leicht recycelbar',
                    tableName: 'Vergleichende Eigenschaften',
                    squircle: 'Squircle',
                    round: 'Kreis',
                    square: 'Quadrat',
                    table: {
                        row1: {
                            name: 'Abmessungen'
                        },
                        row2: {
                            name: 'Integrierte Beleuchtung',
                            prop1: 'Ja',
                            prop2: 'Nein'
                        },
                        row3: {
                            name: 'Sprungtuch',
                            prop1: 'Stoffsprung-tuch',
                            prop2: 'Gummisprung-tuch'
                        },
                        row4: {
                            name: 'Anpassung',
                            prop1: 'Muster',
                            prop2: 'Logo'
                        },
                        row5: {
                            name: 'Schutz',
                            prop1: 'Rund',
                            prop2: 'Hügel',
                            prop3: 'Polster',
                        }
                    },
                },
                button: {
                    text: 'Preisliste anfordern',
                    modal: {
                        title: 'Erhalten Sie Preise',
                        name: 'Ihr Name',
                        email: 'Email',
                        phone: 'Telefon',
                        message: 'Ein Kommentar',
                        accept: 'By checking this box, I accept the',
                        link: 'privacy statement'
                    },
                    submit: 'Bestätigen',
                },
                footer: {
                    catalog: 'Katalog',
                    contacts: 'Kontakte',
                    feedback: 'Preisliste anfordern',
                    submit: 'Bestätigen',
                },
                contacts: {
                    title: "Verbinde dich mit Uns",
                    subtitle: 'begleiten Sie uns',
                    text: 'Kontaktieren Sie uns, wenn Sie Fragen oder Anregungen haben',
                },
                catalog: {
                    in_mound: {
                        title: 'im Hügel',
                        item1: 'Squircle',
                        item2: 'Kreis',
                    },
                    in_ground: {
                        title: 'eingebaut',
                        item1: 'Squircle',
                        item2: 'Kreis',
                        item3: 'Quadrat',
                        item4: 'Rechteck',
                    },
                    sets: {
                        title: 'Sätze',
                        item1: 'Squircle',
                        item2: 'Kreis',
                        item3: 'Quadrat',
                    }
                },
                trompolinos: {
                    types: {
                        protection: {
                            title: 'Schutz',
                            prop1: 'Rand',
                            prop2: 'Polster'
                        },
                        light: {
                            title: 'Integrierte Beleuchtung',
                            prop1: 'Ja',
                            prop2: 'Nein',
                        },
                        membranes: {
                            title: 'Sprungtuch',
                            prop1: 'Textil',
                            prop2: 'Kautschuk'
                        },
                        custom: {
                            title: 'Anpassung',
                            prop1: 'Probe',
                            prop2: 'Logo',
                        },
                    },
                    ground: {
                        squircle: {
                            item1: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Stahlrahmen: 1540x1540 cm',
                                    weight: 'Gewicht: 170 kg',
                                    height: 'Leinwandabmessungen: 1000x1000',
                                    area: 'Schutzzone: 200 cm',
                                    text: 'Das Gerät kann eine Sicherheitszone mit anderen Geräten gemäß EN 1176-1:2017/4.2.8.2.5 teilen.'
                                }
                            },
                            item2: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Stahlrahmen: 2020x2020 cm',
                                    weight: 'Gewicht: 245 kg',
                                    height: 'Leinwandabmessungen: 1500x1500',
                                    area: 'Schutzzone: 200 cm',
                                    text: 'Das Gerät kann eine Sicherheitszone mit anderen Geräten gemäß EN 1176-1:2017/4.2.8.2.5 teilen.'
                                }
                            },
                        },
                        round: {
                            item2: {
                                title: 'Runden',
                                details: {
                                    steel: 'Stahlrahmen: 1600x1600 cm',
                                    weight: 'Gewicht: 160 kg',
                                    height: 'Leinwandabmessungen: ⌀1000',
                                    area: 'Schutzzone: 200 cm',
                                    text: 'Das Gerät kann eine Sicherheitszone mit anderen Geräten gemäß EN 1176-1:2017/4.2.8.2.5 teilen.'
                                }
                            },
                            item1: {
                                title: 'Runden',
                                details: {
                                    steel: 'Stahlrahmen: 2060x2060 cm',
                                    weight: 'Gewicht: 210 kg',
                                    height: 'Leinwandabmessungen: ⌀1480',
                                    area: 'Schutzzone: 200 cm',
                                    text: 'Das Gerät kann eine Sicherheitszone mit anderen Geräten gemäß EN 1176-1:2017/4.2.8.2.5 teilen.'
                                }
                            },
                        },
                        square: {
                            item2: {
                                title: 'Quadrat',
                                details: {
                                    steel: 'Stahlrahmen: 1540x1540 cm',
                                    weight: 'Gewicht: 160 kg',
                                    height: 'Leinwandabmessungen: 150x150',
                                    area: 'Schutzzone: 200 cm',
                                    text: 'Das Gerät kann eine Sicherheitszone mit anderen Geräten gemäß EN 1176-1:2017/4.2.8.2.5 teilen.'
                                }
                            },
                            item1: {
                                title: 'Quadrat',
                                details: {
                                    steel: 'Stahlrahmen: 150x150x30 cm',
                                    weight: 'Gewicht: 170 kg',
                                    height: 'Leinwandabmessungen: 1000x1000',
                                    area: 'Schutzzone: 200 cm',
                                    text: 'Das Gerät kann eine Sicherheitszone mit anderen Geräten gemäß EN 1176-1:2017/4.2.8.2.5 teilen.'
                                }
                            },
                        },
                        rectangle: {
                            item1: {
                                title: 'Rechteckig',
                                details: {
                                    steel: 'Stahlrahmen: 2020x2020 cm',
                                    weight: 'Gewicht: 225 kg',
                                    height: 'Leinwandabmessungen: 1500x1500',
                                    area: 'Schutzzone: 200 cm',
                                    text: 'Das Gerät kann eine Sicherheitszone mit anderen Geräten gemäß EN 1176-1:2017/4.2.8.2.5 teilen.'
                                }
                            }
                        }
                    },
                    mound: {
                        squircle: {
                            item1: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Hügeldimension: 2170х2170 cm',
                                    weight: 'Gewicht: 245 kg',
                                    height: 'Leinwandabmessungen: 1000х1000',
                                    area: 'Schutzzone: 200 cm',
                                    text: 'Das Gerät kann eine Sicherheitszone mit anderen Geräten gemäß EN 1176-1:2017/4.2.8.2.5 teilen.'
                                }
                            },
                            item2: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Hügeldimension: 2650х2650 cm',
                                    weight: 'Gewicht: 395 kg',
                                    height: 'Leinwandabmessungen: 1500x1500',
                                    area: 'Schutzzone: 200 cm',
                                    text: 'Das Gerät kann eine Sicherheitszone mit anderen Geräten gemäß EN 1176-1:2017/4.2.8.2.5 teilen.'
                                }
                            },
                        },
                        round: {
                            item1: {
                                title: 'Runden',
                                details: {
                                    steel: 'ГHügeldimension: ⌀2260 cm',
                                    weight: 'Gewicht: 235 kg',
                                    height: 'Leinwandabmessungen: ⌀1000',
                                    area: 'Schutzzone: 200 cm',
                                    text: 'Das Gerät kann eine Sicherheitszone mit anderen Geräten gemäß EN 1176-1:2017/4.2.8.2.5 teilen.'
                                }
                            },
                            item2: {
                                title: 'Runden',
                                details: {
                                    steel: 'Hügeldimension: ⌀2650 cm',
                                    weight: 'Gewicht: 360 kg',
                                    height: 'Leinwandabmessungen: ⌀1480',
                                    area: 'Schutzzone: 200 cm',
                                    text: 'Das Gerät kann eine Sicherheitszone mit anderen Geräten gemäß EN 1176-1:2017/4.2.8.2.5 teilen.'
                                }
                            },
                        },

                    },
                    sets: {
                        squircle: {
                            item1: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Stahlrahmen: 150x150x30 cm',
                                    weight: 'Gewicht: 160 kg',
                                    height: 'Leinwandabmessungen: 150x150',
                                    area: 'Schutzzone: 200 cm',
                                    text: 'Das Gerät kann eine Sicherheitszone mit anderen Geräten gemäß EN 1176-1:2017/4.2.8.2.5 teilen.'
                                }
                            },
                        },
                        round: {
                            item1: {
                                title: 'Runden',
                                details: {
                                    steel: 'Stahlrahmen: 150x150x30 cm',
                                    weight: 'Gewicht: 160 kg',
                                    height: 'Leinwandabmessungen: 150x150',
                                    area: 'Schutzzone: 200 cm',
                                    text: 'Das Gerät kann eine Sicherheitszone mit anderen Geräten gemäß EN 1176-1:2017/4.2.8.2.5 teilen.'
                                }
                            },
                        },
                        square: {
                            item1: {
                                title: 'Quadrat',
                                details: {
                                    steel: 'Stahlrahmen: 150x150x30 cm',
                                    weight: 'Gewicht: 160 kg',
                                    height: 'Leinwandabmessungen: 150x150',
                                    area: 'Schutzzone: 200 cm',
                                    text: 'Das Gerät kann eine Sicherheitszone mit anderen Geräten gemäß EN 1176-1:2017/4.2.8.2.5 teilen.'
                                }
                            },
                        }
                    }
                },
                downloads: {
                    title: 'Downloads',
                    delivery: 'Versand',
                    certifcation: 'Zertifizierung',
                    contacts:'Kontaktieren Sie uns',
                    certifcationText: 'Entspricht den Anforderungen von TR EAEU 042/2017 und GOST 34614.1-2019',
                    dimensions: ' Maße',
                    product: 'Produktblatt',
                    instruction: 'Installationsanleitung'
                }
            },
        },
        es: {
            translation: {
                header: {
                    catalog: 'Catálogo',
                    contacts: 'Contactos',
                    download: 'Descargar '
                },
                outdors: {
                    text: 'Las camas elásticas integradas al aire libre para los ninos de diferentes edades'
                },
                playground: {
                    title: 'Camas elásticas al aire libre',
                    subtitle: 'Camas elásticas al aire libre para todas.  Las condiciones climáticas, adecuadas para parques, escuelas, jardines de infancia y espacios públicos. Seguras, certificadas y anti-vandalismo',
                    link: 'catálogo en línea',
                    button: 'descargar pdf'
                },
                constructor: {
                    title: 'Elige el tuyo',
                    subtitle: 'Diferentes formas y soluciones para cada tipo de zonas de juego',
                    form: {
                        title: 'Forma',
                        types: {
                            squircle: 'squircle',
                            round: 'circular',
                            square: 'cuadrado'
                        }
                    },
                    protection: {
                        title: 'Protección',
                        types: {
                            ring: 'Borde',
                            tiles: 'Almohadilla',
                            mound: 'Colina'
                        }
                    },
                    light: {
                        title: 'Iluminación incorporada',
                        types: {
                            with: 'Sí',
                            without: 'No',
                        }
                    },
                    membranes: {
                        title: 'Lona de salto',
                        types: {
                            fabric: 'Lona de tela',
                            rubber: 'Lona de goma',
                        }
                    }

                },
                quality: {
                    title: 'Fiabilidad y calidad',
                    subtitle: 'Hemos hecho cada detalle de la cama elástica lo más confiable posible para que no tenga que preocuparse por la durabilidad de nuestros camas elásticas',
                    activeTitle1: 'iluminación LED hermética',
                    activeSubtitle1: 'con protección contra agua, polvo y arena',
                    activeTitle2: 'El borde de protección',
                    activeSubtitle2: 'del anillo aumenta la resistencia al desgaste de la cama elástica',
                    activeTitle3: 'membrana de goma reforzada',
                    activeSubtitle3: 'con orificios para drenar el agua y circular el aire',
                    activeTitle4: 'marco de metal galvanizado en caliente',
                    activeSubtitle4: 'con costillas de refuerzo',
                },
                spring: {
                    title: 'Muelles reforzados',
                    subtitle: 'Hemos desarrollado resortes especiales con una longitud de trabajo extendida y hemos aumentado su tensión inicial en la lona. Los resortes tienen un triple margen de seguridad. Los resortes están hechos de acero galvanizado, lo que garantiza durabilidad y excelente resistencia a la corrosión',
                },
                membranes: {
                    title: 'Lona de salto',
                    rubber: {
                        title: 'Lona de goma',
                        subtitle: 'Una membrana sólida de goma reforzada con dos capas de tela de poliéster. El grosor de la lona es de 6,5 mm. La lona tiene agujeros para el drenaje de precipitaciones y para reducir el efecto de amortiguación del aire durante el uso',
                        text: 'Rango de temperatura de funcionamiento -30..+50 grados',
                        custom: 'Diseño personalizado',
                    },
                    belt: {
                        title: 'Lona de tela',
                        subtitle: 'Cosido y tejido con correas resistentes al desgaste con una carga de rotura de 4.500 kg. El grosor de la lona es de 4,5 mm. Material: poliéster de alta densidad estabilizado contra los rayos UV',
                        text: 'Rango de temperatura de funcionamiento -30..+50 grados',
                    },
                },
                customDesign: {
                    title: 'Diseño personalizado',
                    subtitle: 'Crea un área de juego única con patrones fresados antideslizantes',
                    text: 'A su solicitud, podemos agregar su logotipo o diseño personalizado a la membrana de salto',
                    types: {
                        wavy: 'olas',
                        bubbly: 'tetris',
                        floral: 'flora',
                        custom: 'tu diseño'
                    },
                },
                trampoTypes: {
                    title: 'Protección',
                    ring: {
                        title: 'Borde',
                        text: 'El borde de goma aumenta la resistencia al desgaste del límite de la cama elástica, formando una transición estética a la cubierta sin costuras',
                    },
                    tiles: {
                        title: 'Almohadillas',
                        text: 'Almohadillas absorbentes de impactos de 30 mm de grosor hechas de astillas de goma prensadas',
                    },
                    mound: {
                        title: 'Colina',
                        text: 'Colina protectora hecha de fibra de vidrio y astillas de goma de cualquier color. Adecuado para instalación en una cubierta suelta',
                    },
                },
                ledContainer: {
                    title: 'Технические характеристики',
                    props: {
                        prop1: 'Iluminación LED hermética',
                        prop2: 'Temperatura de color 3000K',
                        prop3: 'Grado de protección IP67.',
                        prop4: 'Opcionalmente se suministra con un transformador IP67 para la red de CA 220V',
                        prop5: '24 V',
                    },
                    text: 'Iluminación',
                    subtext: 'LED incorporada'
                },
                reliability: {
                    title: 'Fiabilidad y calidad',
                    subtitle: 'Hemos hecho cada detalle de la cama elástica lo más confiable posible para que no tenga que preocuparse por cuánto tiempo le durará',
                    temp: {
                        prop1: '-30°C',
                        prop2: '...+50°C',
                        descr: 'Temperatura de funcionamiento'
                    },
                    age: {
                        prop1: '3+',
                        text: 'Edad',
                    },
                    load: {
                        prop1: '800kg',
                        text: 'Carga máxima',
                    },
                    guarantee: {
                        prop1: '6 años',
                        prop2: '2 años',
                        text1: 'Garantía en el marco de acero',
                        text2: 'Garantía en piezas de repuesto',
                    },
                },
                environment: {
                    title: 'Medio ambiente',
                    subtitle: 'Los materiales y soluciones técnicas mantienen su aspecto y funcionalidad con el tiempo y son fáciles de reciclar',
                    tableName: 'Características comparativas',
                    squircle: 'Squircle',
                    round: 'Circular',
                    square: 'Cuadrado',
                    table: {
                        row1: {
                            name: 'Dimensiones'
                        },
                        row2: {
                            name: 'Iluminación incorporada',
                            prop1: 'Sí',
                            prop2: 'No'
                        },
                        row3: {
                            name: 'Lona de salto',
                            prop1: 'Lona de tela',
                            prop2: 'Lona de goma'
                        },
                        row4: {
                            name: 'Personalización',
                            prop1: 'Plantilla',
                            prop2: 'Logotipo'
                        },
                        row5: {
                            name: 'Protección',
                            prop1: 'Borde',
                            prop2: 'Colina',
                            prop3: 'Almohadillas',
                        }
                    },
                },
                button: {
                    text: 'Obtener precios',
                    modal: {
                        title: 'Obtener precios',
                        name: 'Su nombre',
                        email: 'Email',
                        phone: 'Teléfono',
                        message: 'Un comentario',
                        accept: 'By checking this box, I accept the',
                        link: 'privacy statement'
                    },
                    submit: 'Confirmar',
                },
                footer: {
                    catalog: 'Catálogo',
                    contacts: 'Contactos',
                    feedback: 'Obtener precios',
                    submit: 'Confirmar',
                },
                contacts: {
                    title: "Contáctanos",
                    subtitle: 'Únete a nosotros',
                    text: 'Contáctanos si tienes preguntas o deseos',
                },
                catalog: {
                    in_mound: {
                        title: 'En la colina',
                        item1: 'Scuircle',
                        item2: 'Circular',
                    },
                    in_ground: {
                        title: 'Incorporado',
                        item1: 'Scuircle',
                        item2: 'Circular',
                        item3: 'Cuadrado',
                        item4: 'Rectángulo',
                    },
                    sets: {
                        title: 'Conjuntos',
                        item1: 'Scuircle',
                        item2: 'Circular',
                        item3: 'Cuadrado',
                    }
                },
                trompolinos: {
                    types: {
                        protection: {
                            title: 'Protección',
                            prop1: 'Borde',
                            prop2: 'Almohadillas'
                        },
                        light: {
                            title: 'Iluminación incorporada',
                            prop1: 'Sí',
                            prop2: 'No',
                        },
                        membranes: {
                            title: 'Lona de salto',
                            prop1: 'Lona de tela',
                            prop2: 'Lona de goma'
                        },
                        custom: {
                            title: 'Personalización',
                            prop1: 'Plantilla',
                            prop2: 'Logotipo',
                        },
                    },
                    ground: {
                        squircle: {
                            item1: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Marco de acero: 1540x1540 cm',
                                    weight: 'Peso: 170 kg',
                                    height: 'Dimensiones del panel: 1000x1000',
                                    area: 'Zona de protección: 200 cm',
                                    text: 'El dispositivo puede separar la zona de seguridad de otro equipo de acuerdo con EN 1176-1: 2017/4.2.8.2.5.'
                                }
                            },
                            item2: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Marco de acero: 2020x2020 cm',
                                    weight: 'Peso: 245 kg',
                                    height: 'Dimensiones del panel: 1500x1500',
                                    area: 'Zona de protección: 200 cm',
                                    text: 'El dispositivo puede separar la zona de seguridad de otro equipo de acuerdo con EN 1176-1: 2017/4.2.8.2.5.'
                                }
                            },
                        },
                        round: {
                            item2: {
                                title: 'Circular',
                                details: {
                                    steel: 'Marco de acero: 1600x1600 cm',
                                    weight: 'Peso: 160 kg',
                                    height: 'Dimensiones del panel: ⌀1000',
                                    area: 'Zona de protección: 200 cm',
                                    text: 'El dispositivo puede separar la zona de seguridad de otro equipo de acuerdo con EN 1176-1: 2017/4.2.8.2.5.'
                                }
                            },
                            item1: {
                                title: 'Circular',
                                details: {
                                    steel: 'Marco de acero: 2060x2060 cm',
                                    weight: 'Peso: 210 kg',
                                    height: 'Dimensiones del panel: ⌀1480',
                                    area: 'Zona de protección: 200 cm',
                                    text: 'El dispositivo puede separar la zona de seguridad de otro equipo de acuerdo con EN 1176-1: 2017/4.2.8.2.5.'
                                }
                            },
                        },
                        square: {
                            item2: {
                                title: 'Cuadrado',
                                details: {
                                    steel: 'Marco de acero: 1540x1540 cm',
                                    weight: 'Peso: 160 kg',
                                    height: 'Dimensiones del panel: 150x150',
                                    area: 'Zona de protección: 200 cm',
                                    text: 'El dispositivo puede separar la zona de seguridad de otro equipo de acuerdo con EN 1176-1: 2017/4.2.8.2.5.'
                                }
                            },
                            item1: {
                                title: 'Cuadrado',
                                details: {
                                    steel: 'Marco de acero: 150x150x30 cm',
                                    weight: 'Peso: 170 kg',
                                    height: 'Dimensiones del panel: 1000x1000',
                                    area: 'Zona de protección: 200 cm',
                                    text: 'El dispositivo puede separar la zona de seguridad de otro equipo de acuerdo con EN 1176-1: 2017/4.2.8.2.5.'
                                }
                            },
                        },
                        rectangle: {
                            item1: {
                                title: 'Rectángulo',
                                details: {
                                    steel: 'Marco de acero: 2020x2020 cm',
                                    weight: 'Peso: 225 kg',
                                    height: 'Dimensiones del panel: 1500x1500',
                                    area: 'Zona de protección: 200 cm',
                                    text: 'El dispositivo puede separar la zona de seguridad de otro equipo de acuerdo con EN 1176-1: 2017/4.2.8.2.5.'
                                }
                            }
                        }
                    },
                    mound: {
                        squircle: {
                            item1: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Dimensión de la colina: 2170х2170 cm',
                                    weight: 'Peso: 245 kg',
                                    height: 'Dimensiones del panel: 1000х1000',
                                    area: 'Zona de protección: 200 cm',
                                    text: 'El dispositivo puede separar la zona de seguridad de otro equipo de acuerdo con EN 1176-1: 2017/4.2.8.2.5.'
                                }
                            },
                            item2: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Dimensión de la colina: 2650х2650 cm',
                                    weight: 'Peso: 395 kg',
                                    height: 'Dimensiones del panel: 1500x1500',
                                    area: 'Zona de protección: 200 cm',
                                    text: 'El dispositivo puede separar la zona de seguridad de otro equipo de acuerdo con EN 1176-1: 2017/4.2.8.2.5.'
                                }
                            },
                        },
                        round: {
                            item1: {
                                title: 'Circular',
                                details: {
                                    steel: 'Dimensión de la colina: ⌀2260 cm',
                                    weight: 'Peso: 235 kg',
                                    height: 'Dimensiones del panel: ⌀1000',
                                    area: 'Zona de protección: 200 cm',
                                    text: 'El dispositivo puede separar la zona de seguridad de otro equipo de acuerdo con EN 1176-1: 2017/4.2.8.2.5.'
                                }
                            },
                            item2: {
                                title: 'Circular',
                                details: {
                                    steel: 'Dimensión de la colina: ⌀2650 cm',
                                    weight: 'Peso: 360 kg',
                                    height: 'Dimensiones del panel: ⌀1480',
                                    area: 'Zona de protección: 200 cm',
                                    text: 'El dispositivo puede separar la zona de seguridad de otro equipo de acuerdo con EN 1176-1: 2017/4.2.8.2.5.'
                                }
                            },
                        },

                    },
                    sets: {
                        squircle: {
                            item1: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Marco de acero: 150x150x30 cm',
                                    weight: 'Peso: 160 kg',
                                    height: 'Dimensiones del panel: 150x150',
                                    area: 'Zona de protección: 200 cm',
                                    text: 'El dispositivo puede separar la zona de seguridad de otro equipo de acuerdo con EN 1176-1: 2017/4.2.8.2.5.'
                                }
                            },
                        },
                        round: {
                            item1: {
                                title: 'Circular',
                                details: {
                                    steel: 'Marco de acero: 150x150x30 cm',
                                    weight: 'Peso: 160 kg',
                                    height: 'Dimensiones del panel: 150x150',
                                    area: 'Zona de protección: 200 cm',
                                    text: 'El dispositivo puede separar la zona de seguridad de otro equipo de acuerdo con EN 1176-1: 2017/4.2.8.2.5.'
                                }
                            },
                        },
                        square: {
                            item1: {
                                title: 'Cuadrado',
                                details: {
                                    steel: 'Marco de acero: 150x150x30 cm',
                                    weight: 'Peso: 160 kg',
                                    height: 'Dimensiones del panel: 150x150',
                                    area: 'Zona de protección: 200 cm',
                                    text: 'El dispositivo puede separar la zona de seguridad de otro equipo de acuerdo con EN 1176-1: 2017/4.2.8.2.5.'
                                }
                            },
                        }
                    }
                },
                downloads: {
                    title: 'Descargas',
                    delivery: 'Envío',
                    certifcation: 'Certificación',
                    contacts:'Contáctanos',
                    certifcationText: 'Cumple con los requisitos de TR EAEU 042/2017 y GOST 34614.1-2019',
                    dimensions: 'Dimensiones',
                    product: 'Hoja de producto',
                    instruction: 'Instrucciones de instalación'
                }
            },
        },
        fr: {
            translation: {
                header: {
                    catalog: 'Catalogue',
                    contacts: 'Contacts',
                    download: 'Télécharger'
                },
                outdors: {
                    text: 'Trampolines de rue encastrés pour enfants de tous âges'
                },
                playground: {
                    title: 'Trampolines de rue encastrés',
                    subtitle: 'Trampolines de rue tout temps pour les terrains de jeux. Convient aux parcs, aux écoles, aux jardins d’enfants et aux espaces publics. Sûrs, certifiés et anti-vandalisme',
                    link: 'Catalogue en ligne',
                    button: 'Télécharger PDF'
                },
                constructor: {
                    title: 'Choisissez le vôtre',
                    subtitle: 'Différentes formes et solutions pour chaque type d\'aire de jeu',
                    form: {
                        title: 'Forme',
                        types: {
                            squircle: 'Squircle',
                            round: 'Cercle',
                            square: 'Carré'
                        }
                    },
                    protection: {
                        title: 'Protection',
                        types: {
                            ring: 'Bord',
                            tiles: 'Coussins',
                            mound: 'Colline'
                        }
                    },
                    light: {
                        title: 'Éclairage intégré',
                        types: {
                            with: 'Oui',
                            without: 'Non',
                        }
                    },
                    membranes: {
                        title: 'Toile de saut',
                        types: {
                            fabric: 'textil',
                            rubber: 'goma',
                        }
                    }

                },
                quality: {
                    title: 'Fiabilité et qualité',
                    subtitle: 'Nous avons rendu chaque détail du trampoline aussi fiable que possible pour que vous n\'ayez pas à vous soucier de sa durée de vie',
                    activeTitle1: 'éclairage LED étanche',
                    activeSubtitle1: 'avec protection contre l\'eau, la poussière et le sable',
                    activeTitle2: 'anneau de protection',
                    activeSubtitle2: 'augmente la résistance à l’usure du trampoline',
                    activeTitle3: 'membrane en caoutchouc renforcée',
                    activeSubtitle3: 'avec des trous pour le drainage de l’eau et la circulation de l\'air',
                    activeTitle4: 'cadre en métal galvanisé à chaud',
                    activeSubtitle4: 'avec des nervures de rigidité',
                },
                spring: {
                    title: 'Ressorts renforcés',
                    subtitle: 'Nous avons développé des ressorts spéciaux avec une longueur de travail accrue et renforcé leur tension initiale du tissu. Les ressorts ont une résistance trois fois supérieure. Les ressorts sont en acier galvanisé, ce qui garantit la durabilité et une excellente résistance à la corrosion',
                },
                membranes: {
                    title: 'Toile de saut',
                    rubber: {
                        title: 'Toile en caoutchouc',
                        subtitle: "Membrane solide en caoutchouc, renforcée par deux couches de tissu polyester. L'épaisseur de la toile est de 6,5 mm. La toile est perforée de trous pour l'écoulement des précipitations et pour la réduction de l'effet d'amortissement de l'air lors de l'utilisation",
                        text: 'Plage de températures d\'utilisation -30..+50 degrés',
                        custom: 'Conception personnalisée',
                    },
                    belt: {
                        title: 'Toile en tissu',
                        subtitle: 'Cousu et tressé à partir de sangles résistantes à la rupture de 4 500 kg. L\'épaisseur de la toile est de 4,5 mm. Matériel: polyester haute densité stabilisé aux UV',
                        text: 'Plage de températures d\'utilisation -30..+50 degrés',
                    },
                },
                customDesign: {
                    title: 'Conception personnalisée',
                    subtitle: 'Créez une aire de jeu unique avec des motifs fraisés antidérapants',
                    text: 'Sur votre demande, nous pouvons ajouter votre logo ou votre propre design de la membrane de saut',
                    types: {
                        wavy: 'vagues',
                        bubbly: 'tetris',
                        floral: 'flora',
                        custom: 'votre design'
                    },
                },
                trampoTypes: {
                    title: 'Protection',
                    ring: {
                        title: 'Bord',
                        text: 'Le bord en caoutchouc améliore la résistance à l\'usure de la limite du trampoline, créant une transition esthétique vers le revêtement sans couture',
                    },
                    tiles: {
                        title: 'Coussins',
                        text: 'Coussins absorbants les chocs d\'une épaisseur de 30 mm en miettes de caoutchouc pressé',
                    },
                    mound: {
                        title: 'Colline',
                        text: 'Colline de protection en fibre de verre et granulats de caoutchouc de toute couleur. Convient pour une installation dans un revêtement en vrac',
                    },
                },
                ledContainer: {
                    title: 'Caractéristiques techniques',
                    props: {
                        prop1: 'Éclairage LED étanche',
                        prop2: 'Température de couleur 3000K',
                        prop3: 'Indice de protection IP67.',
                        prop4: 'En option, livré avec un transformateur IP67 pour le réseau AC 220V',
                        prop5: '24 V',
                    },
                    text: 'Éclairage',
                    subtext: 'LED intégré'
                },
                reliability: {
                    title: 'Fiabilité et qualité',
                    subtitle: 'Nous avons rendu chaque détail du trampoline aussi fiable que possible pour que vous n\'ayez pas à vous soucier de sa durée de vie',
                    temp: {
                        prop1: '-30°C',
                        prop2: '...+50°C',
                        descr: 'Température d\'utilisation'
                    },
                    age: {
                        prop1: '3+',
                        text: 'Âge',
                    },
                    load: {
                        prop1: '800kg',
                        text: 'Charge maximale',
                    },
                    guarantee: {
                        prop1: '6 ans',
                        prop2: '2 ans',
                        text1: 'Garantie du cadre en acier',
                        text2: 'Garantie des composants',
                    },
                },
                environment: {
                    title: 'Environnement',
                    subtitle: 'Les matériaux et les solutions techniques conservent leur apparence et leur fonctionnalité au fil du temps et sont facilement recyclables',
                    tableName: 'Caractéristiques comparatives',
                    squircle: 'Squircle',
                    round: 'Cercle',
                    square: 'Carré',
                    table: {
                        row1: {
                            name: 'Dimensions'
                        },
                        row2: {
                            name: 'Éclairage intégré',
                            prop1: 'Oui',
                            prop2: 'Non'
                        },
                        row3: {
                            name: 'Toile de saut',
                            prop1: 'Toile en caoutchouc',
                            prop2: 'Obtenir la liste de prix'
                        },
                        row4: {
                            name: 'Personnalisation',
                            prop1: 'Modèle',
                            prop2: 'Logo'
                        },
                        row5: {
                            name: 'Protection',
                            prop1: 'Bord',
                            prop2: 'Colline',
                            prop3: 'Coussins',
                        }
                    },
                },
                button: {
                    text: 'Obtenir la liste de prix',
                    modal: {
                        title: 'Obtenir la liste de prix',
                        name: 'Votre nom',
                        email: 'Email',
                        phone: 'Téléphone',
                        message: 'Un commentaire',
                        accept: 'By checking this box, I accept the',
                        link: 'privacy statement'
                    },
                    submit: 'Confirmer',
                },
                footer: {
                    catalog: 'Catalogue',
                    contacts: 'Contacts',
                    feedback: 'Obtenir la liste de prix',
                    submit: 'Confirmer',
                },
                contacts: {
                    title: "Contacter-nous",
                    subtitle: 'Rejoignez-nous',
                    text: 'Contactez-nous si vous avez des questions ou des souhaits',
                },
                catalog: {
                    in_mound: {
                        title: 'Dans la colline',
                        item1: 'Squircle',
                        item2: 'Cercle',
                    },
                    in_ground: {
                        title: 'вIntégré',
                        item1: 'Squircle',
                        item2: 'Cercle',
                        item3: 'Carré',
                        item4: 'Rectangle',
                    },
                    sets: {
                        title: 'Ensembles',
                        item1: 'Squircle',
                        item2: 'Cercle',
                        item3: 'Carré',
                    }
                },
                trompolinos: {
                    types: {
                        protection: {
                            title: 'Protection',
                            prop1: 'Bord',
                            prop2: 'Coussins'
                        },
                        light: {
                            title: 'Éclairage intégré',
                            prop1: 'Oui',
                            prop2: 'Non',
                        },
                        membranes: {
                            title: 'Toile de saut',
                            prop1: 'textile',
                            prop2: 'gomme'
                        },
                        custom: {
                            title: 'Personnalisation',
                            prop1: 'Modèle',
                            prop2: 'Logo',
                        },
                    },
                    ground: {
                        squircle: {
                            item1: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Cadre en acier: 1540x1540 cm',
                                    weight: 'Poids: 170 kg',
                                    height: 'Dimensions du tapis de saut: 1000x1000',
                                    area: 'Zone de protection: 200 cm',
                                    text: 'L\'appareil peut partager une zone de sécurité avec d\'autres équipements conformément à la norme EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                            item2: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Cadre en acier: 2020x2020 cm',
                                    weight: 'Poids: 245 kg',
                                    height: 'Dimensions du tapis de saut: 1500x1500',
                                    area: 'Zone de protection: 200 cm',
                                    text: 'L\'appareil peut partager une zone de sécurité avec d\'autres équipements conformément à la norme EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        },
                        round: {
                            item2: {
                                title: 'Cercle',
                                details: {
                                    steel: 'Cadre en acier: 1600x1600 cm',
                                    weight: 'Poids: 160 kg',
                                    height: 'Dimensions du tapis de saut: ⌀1000',
                                    area: 'Zone de protection: 200 cm',
                                    text: 'L\'appareil peut partager une zone de sécurité avec d\'autres équipements conformément à la norme EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                            item1: {
                                title: 'Cercle',
                                details: {
                                    steel: 'Cadre en acier: 2060x2060 cm',
                                    weight: 'Poids: 210 kg',
                                    height: 'Dimensions du tapis de saut: ⌀1480',
                                    area: 'Zone de protection: 200 cm',
                                    text: 'L\'appareil peut partager une zone de sécurité avec d\'autres équipements conformément à la norme EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        },
                        square: {
                            item2: {
                                title: 'Carré',
                                details: {
                                    steel: 'Cadre en acier: 1540x1540 cm',
                                    weight: 'Poids: 160 kg',
                                    height: 'Dimensions du tapis de saut: 150x150',
                                    area: 'Zone de protection: 200 cm',
                                    text: 'L\'appareil peut partager une zone de sécurité avec d\'autres équipements conformément à la norme EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                            item1: {
                                title: 'Carré',
                                details: {
                                    steel: 'Cadre en acier: 150x150x30 cm',
                                    weight: 'Poids: 170 kg',
                                    height: 'Dimensions du tapis de saut: 1000x1000',
                                    area: 'Zone de protection: 200 cm',
                                    text: 'L\'appareil peut partager une zone de sécurité avec d\'autres équipements conformément à la norme EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        },
                        rectangle: {
                            item1: {
                                title: 'Прямоугольный',
                                details: {
                                    steel: 'Cadre en acier: 2020x2020 cm',
                                    weight: 'Poids: 225 kg',
                                    height: 'Dimensions du tapis de saut: 1500x1500',
                                    area: 'Zone de protection: 200 cm',
                                    text: 'L\'appareil peut partager une zone de sécurité avec d\'autres équipements conformément à la norme EN 1176-1:2017/4.2.8.2.5.'
                                }
                            }
                        }
                    },
                    mound: {
                        squircle: {
                            item1: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Dimensión de la colina: 2170х2170 cm',
                                    weight: 'Poids: 245 kg',
                                    height: 'Dimensions du tapis de saut: 1000х1000',
                                    area: 'Zone de protection: 200 cm',
                                    text: 'L\'appareil peut partager une zone de sécurité avec d\'autres équipements conformément à la norme EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                            item2: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Dimensión de la colina: 2650х2650 cm',
                                    weight: 'Poids: 395 kg',
                                    height: 'Dimensions du tapis de saut: 1500x1500',
                                    area: 'Zone de protection: 200 cm',
                                    text: 'L\'appareil peut partager une zone de sécurité avec d\'autres équipements conformément à la norme EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        },
                        round: {
                            item1: {
                                title: 'Cercle',
                                details: {
                                    steel: 'Dimensión de la colina: ⌀2260 cm',
                                    weight: 'Poids: 235 kg',
                                    height: 'Dimensions du tapis de saut: ⌀1000',
                                    area: 'Zone de protection: 200 cm',
                                    text: 'L\'appareil peut partager une zone de sécurité avec d\'autres équipements conformément à la norme EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                            item2: {
                                title: 'Cercle',
                                details: {
                                    steel: 'Dimensión de la colina: ⌀2650 cm',
                                    weight: 'Poids: 360 kg',
                                    height: 'Dimensions du tapis de saut: ⌀1480',
                                    area: 'Zone de protection: 200 cm',
                                    text: 'L\'appareil peut partager une zone de sécurité avec d\'autres équipements conformément à la norme EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        },

                    },
                    sets: {
                        squircle: {
                            item1: {
                                title: 'Squircle',
                                details: {
                                    steel: 'Cadre en acier: 150x150x30 cm',
                                    weight: 'Poids: 160 kg',
                                    height: 'Dimensions du tapis de saut: 150x150',
                                    area: 'Zone de protection: 200 cm',
                                    text: 'L\'appareil peut partager une zone de sécurité avec d\'autres équipements conformément à la norme EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        },
                        round: {
                            item1: {
                                title: 'Cercle',
                                details: {
                                    steel: 'Cadre en acier: 150x150x30 cm',
                                    weight: 'Poids: 160 kg',
                                    height: 'Dimensions du tapis de saut: 150x150',
                                    area: 'Zone de protection: 200 cm',
                                    text: 'L\'appareil peut partager une zone de sécurité avec d\'autres équipements conformément à la norme EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        },
                        square: {
                            item1: {
                                title: 'Carré',
                                details: {
                                    steel: 'Cadre en acier: 150x150x30 cm',
                                    weight: 'Poids: 160 kg',
                                    height: 'Dimensions du tapis de saut: 150x150',
                                    area: 'Zone de protection: 200 cm',
                                    text: 'L\'appareil peut partager une zone de sécurité avec d\'autres équipements conformément à la norme EN 1176-1:2017/4.2.8.2.5.'
                                }
                            },
                        }
                    }
                },
                downloads: {
                    title: 'Téléchargements',
                    delivery: 'Livraison',
                    certifcation: 'Certification',
                    contacts:'Contacter-nous',
                    certifcationText: 'Répond aux exigences du TR EAEU 042/2017 et de la norme GOST 34614.1-2019',
                    dimensions: 'Dimensions',
                    product: 'Fiche produit',
                    instruction: "Instructions d'installation",
                }
            },
        },
    },
    lng: 'en',
    fallbackLng: 'ru',
    interpolation: {
        escapeValue: false
    }
})

export default i18n;
