import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { handleDownloadPdf } from "../shared/functions";
const Playground = () => {
  const {t} = useTranslation()
  return (
    <section className="playground">
      <div className="playground--title">
        <h1>{t('playground.title')}</h1>
        <p>
        {t('playground.subtitle')}
        </p>
        <div className="playground--links">
          <div className="catalog">
            <Link to="catalog">{t('playground.link')}</Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
            >
              <path
                d="M4.25682 13.65C3.90322 13.65 3.56322 13.51 3.29122 13.244C2.76082 12.698 2.76082 11.802 3.29122 11.256L7.41202 7L3.27762 2.744C2.74722 2.198 2.74722 1.302 3.27762 0.756003C3.80802 0.210003 4.67842 0.210003 5.20882 0.756003L10.3088 6.006C10.5672 6.272 10.7032 6.622 10.7032 7C10.7032 7.378 10.5536 7.728 10.3088 7.994L5.22242 13.244C4.95042 13.51 4.59682 13.65 4.25682 13.65Z"
                fill="black"
              />
            </svg>
          </div>
          <div onClick={handleDownloadPdf} className="file">
            <p>{t('playground.button')}</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="14"
              viewBox="0 0 17 14"
            >
              <path
                d="M4.25682 13.65C3.90322 13.65 3.56322 13.51 3.29122 13.244C2.76082 12.698 2.76082 11.802 3.29122 11.256L7.41202 7L3.27762 2.744C2.74722 2.198 2.74722 1.302 3.27762 0.756003C3.80802 0.210003 4.67842 0.210003 5.20882 0.756003L10.3088 6.006C10.5672 6.272 10.7032 6.622 10.7032 7C10.7032 7.378 10.5536 7.728 10.3088 7.994L5.22242 13.244C4.95042 13.51 4.59682 13.65 4.25682 13.65Z"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Playground;
