import React from "react";
import { Helmet } from 'react-helmet'
const Politics = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.overflow = "auto";
    }, [])
  return (
<>
<Helmet>
  <title>Tramptec - Politics</title>
</Helmet>
<section className="politics_container">
      <h2>Privacy policy</h2>
      <p>
        This privacy policy explains how Tramptec collects and uses data on
        visitors to our websites. The policy contains information to which you
        are entitled when data is collected from our website and general
        information on how we process personal data.
      </p>
      <p>
        Tramptec’s CEO is the controller for the company’s processing of
        personal data. It is voluntary for visitors to our websites to provide
        personal data in connection with services such as receiving newsletters
        and catalogues. The basis for processing is consent by the individual,
        unless specified otherwise.
      </p>
      <h4>Web analytics</h4>
      <p>
        As an important part of the work to create a user-friendly website, we
        look at the user patterns of those who use it. To analyse this data, we
        use the analysis tool Google Analytics and Yandex Analytics.
      </p>
      <p>
        An IP address is defined as personal data because it can be traced back
        to specific hardware and thus to an individual. A user’s IP address is
        anonymised before the data is saved and processed by Yandex and Google.
        An anonymised IP address can therefore not be used to identity an
        individual user.
      </p>
      <h4>Newsletters:</h4>
      <p>
        Our newsletters are based on a contract. Our newsletters offer news on
        all our specialist areas, services and events. We collect your email
        address when you subscribe to our newsletters. This is mandatory. You
        can also provide your first and last names, but this is voluntary. We
        use the data you provide to adapt the information we send you as best we
        can. For example, some events are only relevant to certain companies and
        jobs. You make a contract with us when you ask to receive newsletters
        via our order page. This data is not shared with other companies, and it
        is erased when we receive notification that you no longer want to
        receive information from us. The data is also erased if we receive
        feedback that your email address is no longer active. When we distribute
        newsletters, we keep statistics on their use, how many people read them
        and what is read. This permits us to give our readers the most relevant
        information possible.
      </p>
      <h4>Requesting a catalogue:</h4>
      <p>
        We send catalogues only if requested to do so by a user on our website.
        Addresses and other data are not saved, and you are only added to the
        newsletter list if you particularly ask to be added.
      </p>
      <h4>Rights:</h4>
      <p>
        Everyone who asks for it is entitled to basic information on how a
        company processes personal data. In this policy, we state which data we
        collect and how it is used.
      </p>
    </section></>

  );
};

export default Politics;
