import React from "react";
import {generateTrampoTypes} from '../shared/constants'
import { useTranslation } from "react-i18next";
const TrampoTypes = () => {
  const {i18n, t} = useTranslation()
  const [trampoTypes, setTrampoTypes] = React.useState(generateTrampoTypes())
  React.useEffect(() => {
    setTrampoTypes(generateTrampoTypes())
  }, [i18n.language])
  return (
    <section className="trampoTypes--container">
      {/* <h2>{t('trampoTypes.title')}</h2> */}
      {trampoTypes.map((el, index) => (
        <div key={el.id} className="trampoTypes--container_item">
          <h2>{el.title}</h2>
          <p>{el.descr}</p>
          <div className='trampoTypes-image'>
            <div className={`image${index}`}></div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default TrampoTypes;
